import { useState } from "react";
import { Box, Button, Card, CardContent, CardMedia, Checkbox, CssBaseline, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import house  from  "../assets/WEBP/house_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import STT from "../components/Scroller/STT";
import Footer from "../components/Footer/Footer";
import ToRechner from "../components/RLE/RLE";
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

import firstImage from "../assets/WEBP/Clehr_Lisa-Maria-03_bearbeitet.webp";
import  secondImage  from  "../assets/WEBP/christoff_neu.webp";
import  thirdImage from  "../assets/WEBP/frank_neu.webp";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import STTMob from "../Mobile/components/Scroller/STT";
import FooterMob from "../Mobile/components/Footer/Footer";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import { Helmet } from 'react-helmet';


export  default  function KontaktForm(){

    

    const [anrede, setAnrede] = useState('');

    const handleChange = (event) => {
      setAnrede(event.target.value);
    };


    const isBig  = useMediaQuery('(min-width:600px)');
    
    if(isBig){

    return(

        

        <div style={{backgroundColor:  "#1a1b4b"}} id="header">
            <div>

            <div style={{justifyContent: "center", alignContent: "center", display: "flex", }}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Kontaktformular</title>

                </Helmet>
                <CssBaseline/>
                <Navbar/>


                    <Card component={Box}  elevation={6} sx={{borderRadius: 5, width: "80vw", marginTop: 20, height:  "auto",  }}  >
                        <Stack direction="row" spacing={0} height="auto" >
                            <Card component={Box} sx={{color: "#ffffff", borderRadius: 0, background: `linear-gradient(to  bottom,  rgba(0,0,0,0.5),  rgba(0,0,0,0.5)), url(${house})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "30vw",  height: "auto"}}>
                                <CardContent sx={{paddingTop: 20, paddingBottom: 20,  justifyContent:  "center", alignContent:  "center", display: "flex"}}>
                                    <div>
                                        <Typography variant="h3" sx={{justifySelf: "center", alignSelf: "center", display:  "flex",  fontWeight: "bold"}}>
                                            Informationen
                                        </Typography>
                                        <Stack direction="column" spacing={6}>
                                            <Stack spacing={2} sx={{marginTop: 6}} direction="column" >

                                                
                                                
                                                <Stack direction="column " >
                                                    <EmailIcon  sx={{alignSelf: "center",   fontSize: "3rem",  color: "#f7a400"}}/>
                                                    <Typography variant="h4" sx={{alignSelf: "center",  fontWeight: "bold", }}>E-Mail</Typography>
                                                    
                                                </Stack>
                                                    <Typography textAlign="center" sx={{fontSize:  "1.2rem"}}>versicherung@mr-money.de</Typography>

                                            </Stack>

                                                

                                            <Stack spacing={2} direction="column"  >
                                                <Stack spacing={2} direction="column ">
                                                    <CallIcon sx={{alignSelf: "center",   fontSize: "3rem", color: "#f7a400"}}/>
                                                    <Typography variant="h4" sx={{alignSelf: "center",  fontWeight: "bold", }}>Telefon</Typography>
                                                    
                                                </Stack>
                                                <Typography textAlign="center"  sx={{fontSize:  "1.2rem"}}>03 72 96 / 92 73 96</Typography>
                                            </Stack>

                                                
                                                
                                            <Stack spacing={2} direction="column"  >
                                                <Stack spacing={3} direction="column ">
                                                    <SupportAgentIcon sx={{alignSelf: "center",  fontSize: "3rem",  color: "#f7a400"}}/>
                                                    <Typography variant="h4" sx={{alignSelf: "center",  fontWeight: "bold", }}>Öffnungszeiten</Typography>
                                                    
                                                </Stack>
                                                <Typography  textAlign="center" sx={{fontSize:  "1.2rem"}}>Montag - Freitag <br/>08:30 - 17:30 Uhr</Typography>
                                            
                                            </Stack>

                                        </Stack>


                                    </div>
                                   
                                

                                </CardContent>

                            </Card>

                            <Card component={Box} sx={{width:  "50vw", borderRadius:  0, }}>
                                <CardContent sx={{paddingTop: 10, paddingBottom: 10,  paddingLeft:  5, paddingRight: 5}}>
                                    <Typography variant="h2" sx={{fontWeight: "bold"}} >Kontaktformular</Typography>
                                    <Divider/>
                                    <form name="kontakt_form" action="contact.php" method="POST"  style={{marginTop: 40,  paddingLeft: 5,  paddingRight: 5, paddingBottom:  40 }}>
                                        <Stack  direction="column" spacing={4}>
                                            <Stack  direction="row" spacing={4} width="80vw">
                                                <FormControl sx={{width: 275}}>
                                                    <InputLabel required id="anrede_input"  >Anrede </InputLabel>
                                                    <Select
                                                    labelId="anrede_input_label"
                                                    id="anrede_input_select"
                                                    name="anrede_input_select"
                                                    value={anrede}
                                                    label="Anrede"
                                                    onChange={handleChange}
                                                    >
                                                    <MenuItem value={"Keine Angabe"}>Keine Angabe</MenuItem>
                                                    <MenuItem value={"Frau"}>Frau</MenuItem>
                                                    <MenuItem value={"Herr"}>Herr</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                
                                                

                                            </Stack>
                                            
                                            <Stack direction="row"  spacing={3}>
                                                <TextField required id="vn_tf" name="vn_tf" label="Vorname " variant="outlined" />
                                                <TextField required id="nn_tf" name="nn_tf" label="Nachname " variant="outlined" />
                                            </Stack>

                                            <Stack  direction={"row"} spacing={3} width="80vw">

                                                <TextField required id="em_tf" name="em_tf" label="E-Mail-Adresse " variant="outlined" />
                                                <TextField required id="tele_tf" name="tele_tf" label="Telefon " variant="outlined" />

                                            </Stack>

                                            <TextField required id="betreff_tf" name="betreff_tf" label="Betreff " variant="outlined" fullWidth/>

                                            <TextField required  id="kommentar_tf" name="kommentar_tf" label="Kommentar " variant="outlined" multiline fullWidth rows={9}/>

                                            <Stack  direction="column" spacing={0}  paddingLeft={1}>
                                                <Typography>Die mit einem Stern (*) markierten Felder sind Pflichtfelder. </Typography>
                                                <FormControl>
                                                    <Typography  >Datenschutz *</Typography>
                                                    <Stack direction="row"  spacing={0}>
                                                        <Checkbox id="ds_accepted" name="ds_accepted" required/>
                                                        <Typography sx={{alignSelf: "center"}}>Ich habe die Datenschutzbestimmungen zur Kenntnis genommen und erkenne diese an.</Typography>
                                                    </Stack>
                                                </FormControl>

                                            </Stack>

                                            <Button type="submit" name="submit_button" variant="contained"  sx={{width: "30vw", borderRadius:  0, justifySelf: "flex-end", alignSelf: "center", display: "flex"}}>Abschicken</Button>


                                        </Stack>

                                    </form>
                                </CardContent>
                            </Card>

                        </Stack>

                    </Card>

                </div>
                <div style={{justifyContent:  "center",  alignContent: "center",  display: "flex",  marginTop:  100}}>
                    <Typography variant="h2" sx={{justifySelf: "center", alignSelf: "center",display:  "flex", fontWeight:  "bold",  color: "#f7a400", marginBottom: 5}}> Unser Team ist immer  für  Sie  da!</Typography>
                </div>
                <div style={{justifyContent: "center", alignContent: "center", display: "flex",  paddingBottom: 100}}>
                <Stack direction="row" spacing={"auto"}  sx={{width: "80vw"}}>

                    
                    <Card sx={{width: "25vw", height: "auto", borderRadius: 5}}>
                        <CardMedia 
                            component={"img"}
                            alt=""
                            loading="lazy"
                            image={secondImage}
                            width={"auto"}
                            height={"auto"}
                            />
                        
                    </Card>

                    <Card sx={{width: "25vw", height: "auto", borderRadius: 5}}>
                        <CardMedia 
                            component={"img"}
                            alt=""
                            loading="lazy"
                            image={thirdImage}
                            width={"auto"}
                            height={"auto"}
                            />
                        
                    </Card>

                    <Card sx={{width: "25vw", height: "auto", borderRadius: 5}}>
                        <CardMedia 
                            component={"img"}
                            alt=""
                            loading="lazy"
                            image={firstImage}
                            width={"auto"}
                            height={"auto"}
                            />
                        
                    </Card>
                </Stack>
                </div>
                
                <ToRechner/>

            </div>

            <STT/>
            <Footer/>

        </div>


    );

    }

    else if(!isBig){

        return(

            <div style={{backgroundColor:  "#1a1b4b"}} id="header">
                <div>
    
                <div >
                    <Helmet>
                        <title>Wohngebäudeversicherung: Kontaktformular</title>
                    </Helmet>
                    <CssBaseline/>
                    <NavbarMob/>
                    
                    
                    <div style={{paddingTop:  100, justifyContent: "center", alignContent: "center",  display: "flex", }}>
                        <Typography  sx={{justifySelf: "center",  alignSelf: "center", display: "flex",  fontSize: "8vw",  textAlign: "center",  width: "80vw", color:  "#f7a400",fontWeight:  "bold"}}>Unser  Team ist immer  für  Sie da!</Typography>
                    </div>
                    
                    <div style={{justifyContent: "center", alignContent: "center", display: "flex",  paddingBottom: 0, paddingTop:  20}}>
                    <Stack direction="row" spacing={0.5}  sx={{width: "80vw"}}>
    
                       
                        <Card sx={{width: "auto", height: "auto", borderRadius: 5}}>
                            <CardMedia 
                                component={"img"}
                                alt=""
                                loading="lazy"
                                image={secondImage}
                                width={"auto"}
                                height={"auto"}
                                />
                            
                        </Card>
    
                        <Card sx={{width: "auto", height: "auto", borderRadius: 5}}>
                            <CardMedia 
                                component={"img"}
                                alt=""
                                loading="lazy"
                                image={thirdImage}
                                width={"auto"}
                                height={"auto"}
                                />
                            
                        </Card>
    
                        <Card sx={{width: "auto", height: "auto", borderRadius: 5}}>
                            <CardMedia 
                                component={"img"}
                                alt=""
                                loading="lazy"
                                image={firstImage}
                                width={"auto"}
                                height={"auto"}
                                />
                            
                        </Card>
                    </Stack>
                    </div>

                        
                <div style={{justifyContent: "center", alignContent: "center", display: "flex", height: "auto"}}>
                        <Card component={Box}  elevation={6} sx={{borderRadius: 5, width: "90vw", marginTop: 8, height:  "auto"}}  >
                            <Stack direction="column" spacing={0} height="auto">
                                <Card component={Box} sx={{paddingBottom: 5, color: "#ffffff", borderRadius: 0, background: `linear-gradient(to  bottom,  rgba(0,0,0,0.5),  rgba(0,0,0,0.5)), url(${house})`, backgroundSize: "cover", backgroundRepeat: "no-repeat", width: "auto",  height: "auto"}}>
                                    <CardContent sx={{paddingTop: 5, paddingBottom: 5,  justifyContent:  "center", alignContent:  "center", display: "flex"}}>
                                        <div>
                                            
                                            <Stack direction="column" spacing={6}>
                                                <Typography sx={{fontSize: "8vw", justifySelf: "center", alignSelf: "center", display:  "flex",  fontWeight: "bold"}}>
                                                    Informationen
                                                </Typography>
                                                <Stack spacing={2} sx={{marginTop: 6}} direction="column" >
    
                                                    
                                                    
                                                    <Stack direction="column " >
                                                        <EmailIcon  sx={{alignSelf: "center",   fontSize: "10vw",  color: "#f7a400"}}/>
                                                        <Typography sx={{fontSize: "7vw", alignSelf: "center",  fontWeight: "bold", }}>E-Mail</Typography>
                                                        
                                                    </Stack>
                                                        <Typography textAlign="center" sx={{fontSize:  "5vw"}}>versicherung@mr-money.de</Typography>
    
                                                </Stack>
    
                                                    
    
                                                <Stack spacing={2} direction="column"  >
                                                    <Stack spacing={2} direction="column ">
                                                        <CallIcon sx={{alignSelf: "center",   fontSize: "10vw", color: "#f7a400"}}/>
                                                        <Typography sx={{alignSelf: "center",  fontWeight: "bold", fontSize: "7vw"}}>Telefon</Typography>
                                                        
                                                    </Stack>
                                                    <Typography textAlign="center"  sx={{fontSize:  "5vw"}}>03 72 96 / 92 73 96</Typography>
                                                </Stack>
    
                                                    
                                                    
                                                <Stack spacing={2} direction="column"  >
                                                    <Stack spacing={3} direction="column ">
                                                        <SupportAgentIcon sx={{alignSelf: "center",  fontSize: "10vw",  color: "#f7a400"}}/>
                                                        <Typography sx={{alignSelf: "center",  fontWeight: "bold", fontSize: "7vw"}}>Öffnungszeiten</Typography>
                                                        
                                                    </Stack>
                                                    <Typography  textAlign="center" sx={{fontSize:  "5vw"}}>Montag - Freitag <br/>08:30 - 17:30 Uhr</Typography>
                                                
                                                </Stack>
    
                                            </Stack>
    
    
                                        </div>
                                       
                                    
    
                                    </CardContent>
    
                                </Card>
    
                                <Card component={Box} sx={{width:  "auto", borderRadius:  0, paddingBottom: 5}}>
                                    <CardContent sx={{padding:2}}>
                                        <div  style={{justifyContent:  "center",  alignContent: "center", display: "flex"}}>
                                            <Typography sx={{fontWeight: "bold", fontSize:  "8.5vw", justifySelf: "center", alignSelf: "center", display: "flex"}} >Kontaktformular</Typography>
                                        </div>
                                        <Divider/>
                                        <form name="kontakt_form"  action="contact.php" method="POST" style={{marginTop: 40,  paddingLeft: 5,  paddingRight: 5 }}>
                                            <Stack  direction="column" spacing={4}>
                                                <Stack  direction="column" spacing={4} width="80vw">
                                                    <FormControl >
                                                        <InputLabel required id="anrede_input"  >Anrede </InputLabel>
                                                        <Select
                                                        labelId="anrede_input_label"
                                                        id="anrede_input_select"
                                                        name="anrede_input_select"
                                                        value={anrede}
                                                        label="Anrede"
                                                        onChange={handleChange}  
                                                        
                                                        >
                                                        <MenuItem value={"Keine Angabe"}>Keine Angabe</MenuItem>
                                                        <MenuItem value={"Frau"}>Frau</MenuItem>
                                                        <MenuItem value={"Herr"}>Herr</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                    
                                                    <TextField required id="vn_tf" name="vn_tf" label="Vorname " variant="outlined" />
                                                    <TextField required id="nn_tf" name="nn_tf" label="Nachname " variant="outlined" />
    
                                                </Stack>
    
                                                <Stack  direction={"row"} spacing={3} width="80vw">
    
                                                    <TextField required id="em_tf" name="em_tf" label="E-Mail-Adresse " variant="outlined" />
                                                    <TextField required id="tele_tf" name="tele_tf" label="Telefon " variant="outlined" />
    
                                                </Stack>
    
                                                <TextField required id="betreff_tf" name="betreff_tf" label="Betreff " variant="outlined" fullWidth/>
    
                                                <TextField required  id="kommentar_tf" name="kommentar_tf" label="Kommentar " variant="outlined" multiline fullWidth rows={9}/>
    
                                                <Stack  direction="column" spacing={0}  >
                                                    <Typography>Die mit einem Stern (*) markierten Felder sind Pflichtfelder. </Typography>
                                                    <FormControl>
                                                        <Typography sx={{fontSize: "4vw"}}>Datenschutz *</Typography>
                                                        <Stack direction="row"  spacing={0} sx={{marginTop: 2}}>
                                                            <Checkbox required name="ds_accepted" sx={{alignSelf: "flex-start"}}/>
                                                            <Typography  sx={{alignSelf: "flex-end", fontSize: "4vw"}}>Ich habe die Datenschutzbestimmungen zur Kenntnis genommen und erkenne diese an.</Typography>
                                                        </Stack>
                                                    </FormControl>
    
                                                </Stack>
    
                                                <Button type="submit" variant="contained"  sx={{fontSize: "4vw", width: "40vw", borderRadius:  2, justifySelf: "flex-end", alignSelf: "center", display: "flex"}}>Abschicken</Button>
    
    
                                            </Stack>
    
                                        </form>
                                    </CardContent>
                                </Card>
    
                            </Stack>
    
                        </Card>
                    </div>
    
                    </div>
                  
                    
                    <ToRechnerMob/>
    
                </div>
    
                <STTMob/>
                <FooterMob/>
    
            </div>
    
        );

    }

}