
import { CssBaseline, List, ListItem, Stack, Typography, useMediaQuery } from "@mui/material";
import startImage from "../assets/WEBP/faq_image5.webp";
import startImageHandy from "../assets/WEBP/faq_image5_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import ToRechner from "../components/RLE/RLE";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import { Helmet } from 'react-helmet';


export default function  PHOSite(){

    const isBig  = useMediaQuery('(min-width:600px)');
    
    if(isBig){

    return(
        <div id="header">
        <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Photovoltaik</title>
                <meta name="description" content="Photovoltaik: Was sollten Sie beachten in der Wohngebäudeversicherung."/>
                <meta name="keywords" content="Wohngebäudeversicherung Photovoltaik, Wohngebäudeversicherung Solar, Erneuerbare Energien, Wohngebäudeversicherung Grüne Energie" />

                
            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>

                <Typography variant="h1" sx={{justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Photovoltaik</Typography>

            </div>
            <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                <div style={{width: "60vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                    <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "1.2rem",}}>Die Mitversicherung von Photovoltaikanlagen in der Wohngebäudeversicherung ist ein wichtiger Aspekt, den Hausbesitzer bei der Auswahl einer passenden Versicherung berücksichtigen sollten. Eine Photovoltaikanlage auf dem Dach oder an der Fassade eines Hauses kann erhebliche finanzielle Investitionen darstellen und stellt daher ein wichtiges Versicherungsgut dar.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Eine Wohngebäudeversicherung deckt in der Regel nicht nur Schäden am eigentlichen Wohngebäude ab, sondern kann auch die Mitversicherung von Photovoltaikanlagen umfassen. Hierbei handelt es sich in der Regel um eine zusätzliche Klausel im Versicherungsvertrag, die explizit die Photovoltaikanlage mit einschließt.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Im Falle eines Schadens an der Photovoltaikanlage, beispielsweise durch Sturm oder Hagel, kann die Wohngebäudeversicherung den finanziellen Schaden abdecken. Auch Schäden durch Brand oder Blitzschlag können hierbei versichert werden.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Wichtig zu beachten ist, dass die Höhe der Versicherungsprämie von verschiedenen Faktoren abhängt, wie zum Beispiel der Größe und Leistung der Photovoltaikanlage sowie der Region, in der das Haus steht. Daher ist es sinnvoll, im Vorfeld verschiedene Angebote von Versicherungsunternehmen zu vergleichen, um das beste Preis-Leistungs-Verhältnis zu finden.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Es ist auch wichtig, den Versicherungsvertrag regelmäßig zu überprüfen und sicherzustellen, dass die Photovoltaikanlage nach wie vor ausreichend versichert ist. Eventuell ist es auch notwendig, den Versicherungsschutz entsprechend anzupassen, wenn sich die Anlage verändert oder erweitert wird.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Insgesamt bietet die Mitversicherung von Photovoltaikanlagen in der Wohngebäudeversicherung eine wichtige Absicherung für Hausbesitzer und trägt dazu bei, dass auch größere Schäden durch Naturereignisse oder technische Defekte abgedeckt werden können.</Typography>
                    </Stack>
                
                </div>
            </div>

            <ToRechner/>

        </div>
        <Footer/>
        <STT/>
        </div>
    );

    }

    else if(!isBig){
        return(
            <div id="header">
            <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Photovoltaik</title>
                    <meta name="description" content="Photovoltaik: Was sollten Sie beachten in der Wohngebäudeversicherung."/>
                    <meta name="keywords" content="Wohngebäudeversicherung Photovoltaik, Wohngebäudeversicherung Solar, Erneuerbare Energien, Wohngebäudeversicherung Grüne Energie" />


                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>
    
                    <Typography  sx={{fontSize: "15vw",justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Photovoltaik</Typography>
    
                </div>
                <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                    <div style={{width: "80vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                        <Stack direction="column" spacing={5}>
                            <Typography sx={{fontSize:  "5vw",}}>Die Mitversicherung von Photovoltaikanlagen in der Wohngebäudeversicherung ist ein wichtiger Aspekt, den Hausbesitzer bei der Auswahl einer passenden Versicherung berücksichtigen sollten. Eine Photovoltaikanlage auf dem Dach oder an der Fassade eines Hauses kann erhebliche finanzielle Investitionen darstellen und stellt daher ein wichtiges Versicherungsgut dar.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Eine Wohngebäudeversicherung deckt in der Regel nicht nur Schäden am eigentlichen Wohngebäude ab, sondern kann auch die Mitversicherung von Photovoltaikanlagen umfassen. Hierbei handelt es sich in der Regel um eine zusätzliche Klausel im Versicherungsvertrag, die explizit die Photovoltaikanlage mit einschließt.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Im Falle eines Schadens an der Photovoltaikanlage, beispielsweise durch Sturm oder Hagel, kann die Wohngebäudeversicherung den finanziellen Schaden abdecken. Auch Schäden durch Brand oder Blitzschlag können hierbei versichert werden.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Wichtig zu beachten ist, dass die Höhe der Versicherungsprämie von verschiedenen Faktoren abhängt, wie zum Beispiel der Größe und Leistung der Photovoltaikanlage sowie der Region, in der das Haus steht. Daher ist es sinnvoll, im Vorfeld verschiedene Angebote von Versicherungsunternehmen zu vergleichen, um das beste Preis-Leistungs-Verhältnis zu finden.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Es ist auch wichtig, den Versicherungsvertrag regelmäßig zu überprüfen und sicherzustellen, dass die Photovoltaikanlage nach wie vor ausreichend versichert ist. Eventuell ist es auch notwendig, den Versicherungsschutz entsprechend anzupassen, wenn sich die Anlage verändert oder erweitert wird.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Insgesamt bietet die Mitversicherung von Photovoltaikanlagen in der Wohngebäudeversicherung eine wichtige Absicherung für Hausbesitzer und trägt dazu bei, dass auch größere Schäden durch Naturereignisse oder technische Defekte abgedeckt werden können.</Typography>
                        </Stack>
                    
                    </div>
                </div>
    
                <ToRechnerMob/>
    
            </div>
            <FooterMob/>
            <STTMob/>
            </div>
        );

    }
}