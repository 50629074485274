import { Box, Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";

import geschuetzt  from "../../../assets/WEBP/geschuetzt_n.webp";
import verpflichtend from  "../../../assets/WEBP/verpflichtung_n.webp";
import  wissenswertes  from "../../../assets/wissenswertes.png";
import  ht  from  "../../../assets/WEBP/haeufige_themen.webp";
import  currentT from "../../../assets/WEBP/currentT.webp";
import useful from  "../../../assets/WEBP/useful.webp";


export default  function  HomeContentBottomMob(){

    return(

        <div  >
        <div style={{justifyContent: "center", alignContent: "center", display: "flex", marginTop: 100, paddingBottom:  100, }}>
            <div style={{width: "80vw", justifyContent:  "center", alignContent: "center", display:  "flex"}}>
                <Stack  direction="column" spacing={8}  width="80vw">
                    <Stack direction="column" spacing={3} >
                        <Card component={Box} sx={{width: "auto", height: 300, borderRadius:  2, background: `linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('${geschuetzt}')`, backgroundRepeat: "no-repeat", backgroundSize:  "cover"}} elevation={6}>
                            
                            <CardContent sx={{justifyContent: "center", alignItems: "center", display: "flex", width:  "auto", height: 300}}>
                                <Typography variant="h4" sx={{fontSize: "8vw", justifySelf: "center", alignSelf: "center", display: "flex", color: "#ffffff",  fontWeight: "bold",  textAlign: "center"}}>Versicherungs-<br/>prämie?</Typography>
                            </CardContent>

                            
                        </Card>
                        
                            <Typography p={1} sx={{fontSize:  "4vw", alignSelf: "center", color: "#ffffff",  justifySelf:  "left",display: "flex", fontFamily: 'Plus Jakarta Sans, sans-serif'}}>
                                Die Höhe der Versicherungsprämie richtet sich in der Regel nach dem Wert des Hauses, der Lage und den individuellen Bedürfnissen des Hausbesitzers. Es ist wichtig, eine ausreichende Versicherungssumme zu wählen, um im Falle eines Schadens eine angemessene Entschädigung zu erhalten.                            </Typography>
                        
                    </Stack>
                    <Stack direction="column" spacing={3} >
                                             
                        <Card component={Box} sx={{width: "auto",height: 300,  borderRadius: 2, background: `linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('${verpflichtend}')`, backgroundRepeat: "no-repeat", backgroundSize:  "cover"}} elevation={6}>
                            <CardContent sx={{justifyContent: "center", alignItems: "center", display: "flex", width:  "auto", height: 300}}>
                                <Typography variant="h4" sx={{fontSize: "8vw", justifySelf: "center", alignSelf: "center", display: "flex", color: "#ffffff",  fontWeight: "bold"}}>Verpflichtend?</Typography>
                            </CardContent>
                            
                        </Card>

                        <Typography p={1} sx={{fontSize:  "4vw",  alignSelf: "center", color: "#ffffff", fontFamily: 'Plus Jakarta Sans, sans-serif'}}>
                            In einigen Fällen kann es vorkommen, dass eine Wohngebäudeversicherung für das Haus verpflichtend ist. Zum Beispiel kann dies der Fall sein, wenn das Haus noch finanziert wird und die Bank als Kreditgeber eine Versicherung als Bedingung für die Kreditvergabe verlangt.    
                        </Typography>
                        
                    </Stack>

                     

                </Stack>

                
                   
            </div>
            
        </div>
        
        <div style={{justifyContent:  "center", alignContent: "center", display:  "flex", marginTop:  20,  marginBottom: 100, color: "#ffffff"}}>
            <Stack  direction="column" sx={{justifyContent:  "center", alignContent: "center", display:  "flex", width: "60vw"}}>

                <Stack  direction="column" spacing="auto" sx={{justifySelf:  "center",  alignSelf:  "center",  display: "flex", width:  "80vw"}}>
                    
                    <img alt="" loading="lazy"  src={wissenswertes}  width={"auto"}/>
                    <Typography  textAlign={"center"} color="#f7a400" sx={{fontSize: "9vw", justifySelf: "center",  alignSelf:  "center", display: "flex",  fontWeight:  "bold"}}>Wissenswertes</Typography>

                </Stack>
                
                <Stack sx={{marginTop: 10,  width: "80vw", justifySelf: "center", alignSelf: "center", display: "flex"}}  direction="column" spacing={3}>

                    <Card width={300} height={500}  elevation={4} sx={{padding:  5}}>
                    <Stack direction="column">
                    <Card width="auto" height="auto" elevation={5}>
                        <CardMedia
                            component={"img"}
                            alt=""
                            loading="lazy"
                            image={ht}
                            width={300}
                            height={200}
                        />

                    </Card>
                    <Stack  direction="column" height={"auto"} mt={5}>
                        <Typography  sx={{fontWeight:  "bold", fontSize:  "6vw"}} textAlign="center" mb={3}>Wichtige Themen</Typography>

                        <a href="/haeufigste_schaeden" title="Häufigste Schäden  der Wohngebäudeversicherung" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Häufigste Schäden</span></Typography></a>
                        <a href="/glasbruch" title="Wohngebäudeversicherung: Glasbruch/Schäden" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} mt={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Glasbruch</span></Typography></a>
                        <a href="/elementar" title="Wohngebäudeversicherung: Elementar" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} mt={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Elementar</span></Typography></a>
                        <a href="/hochwasser" title="Wohngebäudeversicherung: Hochwasser" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} mt={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Hochwasser</span></Typography></a>
                        
                        
                    </Stack>
                    </Stack>
                    </Card>

                    <Card width={300} height={500}  elevation={4} sx={{padding:  5}}>
                    <Stack direction="column">
                    <Card width="auto" height="auto" elevation={5}>
                        <CardMedia
                            component={"img"}
                            alt=""
                            loading="lazy"
                            image={currentT}
                            width={300}
                            height={200}
                        />
                    </Card>
                    
                    <Stack  direction="column" height={"auto"} mt={5}>
                        <Typography sx={{fontWeight:  "bold", fontSize:  "6vw"}} textAlign="center" mb={3}>Aktuelle Themen</Typography>
                        
                        <a href="/photovoltaik" title="Wohngebäudeversicherung: Photovoltaik" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Photovoltaik</span></Typography></a>
                        <a href="/vergleiche_im_internet" title="Wohngebäudeversicherung: Vergleiche" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} mt={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Vergleiche im Int.</span></Typography></a>
                        <a href="/testsieger" title="Wohngebäudeversicherung: Testsieger" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} mt={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Testsieger</span></Typography></a>
                        <a href="/erfahrungen_wg" title="Wohngebäudeversicherung: Erfahrungen" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} mt={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Erfahrungen  WG</span></Typography></a>
                        
                    </Stack>
                    </Stack>
                    </Card>

                    <Card width={300} height={500}  elevation={4} sx={{padding:  5}}>
                    <Stack direction="column">
                    <Card width="auto" height="auto" elevation={5}>
                        <CardMedia
                            component={"img"}
                            alt=""
                            loading="lazy"
                            image={useful}
                            width={300}
                            height={200}
                        />
                    </Card>
                    
                    <Stack  direction="column" height={"auto"} mt={5}>
                        <Typography variant="h5" sx={{fontWeight:  "bold", fontSize:  "6vw"}} textAlign="center" mb={3}>Nützliche Themen</Typography>

                        <a href="/neubau" title="Wohngebäudeversicherung: Neubau" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Neubau</span></Typography></a>
                        <a href="/wohnflaeche" title="Wohngebäudeversicherung: Wohnfläche" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} mt={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Wohnfläche</span></Typography></a>
                        <a href="/vermieter" title="Wohngebäudeversicherung: Vermieter" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={2} mr={2} mt={2} color="#f7a400" fontSize={"5vw"}> &bull; <span style={{color: "#000000"}}>Vermieter</span></Typography></a>

                       
                            
                    </Stack>
                    </Stack>
                    </Card>
                </Stack>
            </Stack>
        </div>

        </div>
    );
}