import { Card, CardContent, CssBaseline, useMediaQuery } from "@mui/material";
import  React from "react";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import FooterMob from "../Mobile/components/Footer/Footer";
import { Helmet } from 'react-helmet';


export default function ImpSite() {
  
  getterContent();

  const isBig  = useMediaQuery('(min-width:600px)');

    if(isBig){

  return(
    <div style={{backgroundColor:  "#1a1b4b"}}>
      <Helmet>
                <title>Wohngebäudeversicherung: Impressum</title>
            </Helmet>
      <CssBaseline/>
      <Navbar/>
    <div  style={{ paddingTop:  100, paddingBottom:  50 }}>
      <div style={{justifyContent:"center", alignContent:  "center",  display: "flex"}}>
        <Card sx={{width: "60vw", height: "auto", justifyContent: "center",  alignContent:  "center",  display:  "flex"}}>
          <CardContent id="content" sx={{padding: 10, textAlign: "left"}} >
      
          </CardContent>
        </Card>
      </div>
    </div>
    <Footer/>
    </div>
  );

    }
  
  else if(!isBig){
    return(
      <div style={{backgroundColor:  "#1a1b4b"}}>
        <Helmet>
                <title>Wohngebäudeversicherung: Impressum</title>
        </Helmet>
        <CssBaseline/>
        <NavbarMob/>
      <div  style={{ paddingTop:  100, paddingBottom:  100 }}>
        <div style={{justifyContent:"center", alignContent:  "center",  display: "flex"}}>
          <Card sx={{width: "95vw", height: "auto", justifyContent: "center",  alignContent:  "center",  display:  "flex"}}>
            <CardContent id="content" sx={{padding: 2, textAlign: "left"}} >
        
            </CardContent>
          </Card>
        </div>
      </div>
      <FooterMob/>
      </div>
    );

  }
}

async function getterContent(){

  let file =  "Imp.txt";
  let x =  await fetch(file);
  let y =  await x.text();
  document.getElementById("content").innerHTML =  y.toString();

  

}
