
import { CssBaseline, List, ListItem, Stack, Typography, useMediaQuery } from "@mui/material";
import startImage from "../assets/WEBP/faq_image5.webp";
import startImageHandy from "../assets/WEBP/faq_image5_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import ToRechner from "../components/RLE/RLE";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import { Helmet } from 'react-helmet';


export default function  NBSite(){

    const isBig  = useMediaQuery('(min-width:600px)');
    
    if(isBig){

    return(
        <div id="header">
        <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Neubau</title>
                <meta name="description" content="Welche Besonderheiten sind bei Neubauten zu beachten"/>
                <meta name="keywords" content="Wohngebäudeversicherung Neubau, Wohngebäudeversicherung Bau, Wohngebäudeversicherung Gebäude, Wohngebäudeversicherung Wasser, Wohngebäudeversicherung Hochwasser" />

                
            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>

                <Typography variant="h1" sx={{justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Neubau</Typography>

            </div>
            <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                <div style={{width: "60vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                    <Stack direction="column" spacing={5}>
                    <Typography sx={{fontSize:  "1.2rem",}}>Wenn Sie ein neues Haus bauen, sollten Sie unbedingt eine Wohngebäudeversicherung abschließen, um Ihr Eigentum vor Schäden durch Feuer, Sturm, Leitungswasser und andere Risiken zu schützen. Eine Wohngebäudeversicherung für Neubauten bietet einen umfassenden Versicherungsschutz für Ihr Haus, einschließlich der Bauleistungen, die für die Errichtung des Gebäudes erforderlich sind.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Eine wichtige Komponente einer Wohngebäudeversicherung für Neubauten ist die beitragsfreie Rohbauversicherung. Diese Versicherung bietet einen Schutz für Ihr Gebäude während der Bauphase. In dieser Phase können verschiedene Risiken auftreten, wie zum Beispiel Schäden durch extreme Witterungsbedingungen oder Diebstahl von Baumaterialien.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Die beitragsfreie Rohbauversicherung gilt in der Regel für einen Zeitraum von sechs bis zwölf Monaten und ist in der Wohngebäudeversicherung bereits enthalten. Die genaue Dauer hängt von den Bedingungen des Versicherungsunternehmens ab. Diese Versicherung deckt in der Regel auch die Kosten für notwendige Reparaturen oder Wiederherstellung nach einem Schadenereignis ab.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Nach Abschluss der Bauphase muss die beitragsfreie Rohbauversicherung in eine normale Wohngebäudeversicherung umgewandelt werden. Hierbei sollten Sie darauf achten, dass der Versicherungsschutz Ihren individuellen Bedürfnissen entspricht und mögliche Zusatzleistungen wie z.B. eine Elementarschadenversicherung beinhaltet.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Es ist auch wichtig zu beachten, dass der Versicherungsschutz während der Bauphase nicht automatisch greift. In der Regel muss die Versicherung vor Baubeginn abgeschlossen werden und der Versicherungsvertrag muss die Bauphase explizit abdecken.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Insgesamt ist eine Wohngebäudeversicherung für Neubauten mit einer beitragsfreien Rohbauversicherung ein wichtiger Schutz für Ihr Eigentum während der Bauphase und darüber hinaus. Es ist jedoch wichtig, den Versicherungsschutz sorgfältig zu prüfen und sicherzustellen, dass er Ihren individuellen Bedürfnissen entspricht.</Typography>
                    </Stack>
                
                </div>
            </div>

            <ToRechner/>

        </div>
        <Footer/>
        <STT/>
        </div>
    );

    }

    else if(!isBig){
        
        return(
            <div id="header">
            <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Neubau</title>
                    <meta name="description" content="Welche Besonderheiten sind bei Neubauten zu beachten"/>
                    <meta name="keywords" content="Wohngebäudeversicherung Neubau, Wohngebäudeversicherung Bau, Wohngebäudeversicherung Gebäude, Wohngebäudeversicherung Wasser, Wohngebäudeversicherung Hochwasser" />


                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>
    
                    <Typography sx={{fontSize: "15vw",justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Neubau</Typography>
    
                </div>
                <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                    <div style={{width: "80vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                        <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "5vw",}}>Wenn Sie ein neues Haus bauen, sollten Sie unbedingt eine Wohngebäudeversicherung abschließen, um Ihr Eigentum vor Schäden durch Feuer, Sturm, Leitungswasser und andere Risiken zu schützen. Eine Wohngebäudeversicherung für Neubauten bietet einen umfassenden Versicherungsschutz für Ihr Haus, einschließlich der Bauleistungen, die für die Errichtung des Gebäudes erforderlich sind.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Eine wichtige Komponente einer Wohngebäudeversicherung für Neubauten ist die beitragsfreie Rohbauversicherung. Diese Versicherung bietet einen Schutz für Ihr Gebäude während der Bauphase. In dieser Phase können verschiedene Risiken auftreten, wie zum Beispiel Schäden durch extreme Witterungsbedingungen oder Diebstahl von Baumaterialien.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Die beitragsfreie Rohbauversicherung gilt in der Regel für einen Zeitraum von sechs bis zwölf Monaten und ist in der Wohngebäudeversicherung bereits enthalten. Die genaue Dauer hängt von den Bedingungen des Versicherungsunternehmens ab. Diese Versicherung deckt in der Regel auch die Kosten für notwendige Reparaturen oder Wiederherstellung nach einem Schadenereignis ab.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Nach Abschluss der Bauphase muss die beitragsfreie Rohbauversicherung in eine normale Wohngebäudeversicherung umgewandelt werden. Hierbei sollten Sie darauf achten, dass der Versicherungsschutz Ihren individuellen Bedürfnissen entspricht und mögliche Zusatzleistungen wie z.B. eine Elementarschadenversicherung beinhaltet.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Es ist auch wichtig zu beachten, dass der Versicherungsschutz während der Bauphase nicht automatisch greift. In der Regel muss die Versicherung vor Baubeginn abgeschlossen werden und der Versicherungsvertrag muss die Bauphase explizit abdecken.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Insgesamt ist eine Wohngebäudeversicherung für Neubauten mit einer beitragsfreien Rohbauversicherung ein wichtiger Schutz für Ihr Eigentum während der Bauphase und darüber hinaus. Es ist jedoch wichtig, den Versicherungsschutz sorgfältig zu prüfen und sicherzustellen, dass er Ihren individuellen Bedürfnissen entspricht.</Typography>
                        </Stack>
                    
                    </div>
                </div>
    
                <ToRechnerMob/>
    
            </div>
            <FooterMob/>
            <STTMob/>
            </div>
        );

    }
}