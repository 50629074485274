
import { CssBaseline, Stack, Typography, useMediaQuery } from "@mui/material";
import startImage from "../assets/WEBP/faq_image5.webp";
import startImageHandy from "../assets/WEBP/faq_image5_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import ToRechner from "../components/RLE/RLE";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import { Helmet } from 'react-helmet';


export default function  ElementarsSite(){

    const isBig  = useMediaQuery('(min-width:600px)');

    if(isBig){


    return(
        <div id="header">
        <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Elementarschäden</title>
                <meta name="description" content="Elementarschäden: Warum diese in der Wohngebäudeversicherung mitversichert werden sollten."/>
                
                <meta name="keywords" content="Testsieger Wohngebäudeversicherung, Wohngebäudeversicherung Glas, Wohngebäudeversicherung Elementar, Wohngebäudeversicherung Blitzschlag, Wohngebäudeversicherung Rückstau, Wohngebäudeversicherung Erfahrungen" />
            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>

                <Typography variant="h1" sx={{justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Elementar</Typography>

            </div>
            <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                <div style={{width: "60vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                    <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "1.2rem",}}>Die Mitversicherung von Elementarschäden in der Wohngebäudeversicherung ist ein wichtiger Aspekt, den Hausbesitzer bei der Auswahl einer passenden Versicherung berücksichtigen sollten. Elementarschäden können durch Naturereignisse wie Hochwasser, Überschwemmungen, Erdrutsche, Lawinen oder auch durch Schneedruck oder Sturmfluten verursacht werden. Diese Schäden können erhebliche finanzielle Belastungen darstellen und eine wichtige Absicherung ist hierbei unverzichtbar.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Eine Wohngebäudeversicherung deckt in der Regel Schäden am eigentlichen Wohngebäude ab. Im Rahmen der Mitversicherung von Elementarschäden werden jedoch auch Schäden abgedeckt, die durch extreme Witterungsbedingungen oder andere Naturkatastrophen verursacht werden.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Die Kosten für die Versicherung von Elementarschäden hängen von verschiedenen Faktoren ab, wie z.B. der Lage des Wohngebäudes und der Wahrscheinlichkeit von Naturkatastrophen in der Region. Die Versicherungsprämie kann hierbei höher ausfallen als bei einer reinen Wohngebäudeversicherung. Es ist daher ratsam, verschiedene Angebote von Versicherungsunternehmen zu vergleichen, um das beste Preis-Leistungs-Verhältnis zu finden.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Wichtig zu beachten ist, dass die Mitversicherung von Elementarschäden in der Wohngebäudeversicherung nicht automatisch im Versicherungsumfang enthalten ist. Hausbesitzer sollten daher prüfen, ob diese Absicherung im Vertrag enthalten ist oder ob sie als zusätzliche Klausel ergänzt werden muss.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Im Falle eines Schadens durch einen Elementarschaden kann die Wohngebäudeversicherung den finanziellen Schaden abdecken. Hierbei kann es sich um Reparaturkosten oder auch um den Wiederbeschaffungswert des Wohngebäudes handeln.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Insgesamt bietet die Mitversicherung von Elementarschäden in der Wohngebäudeversicherung eine wichtige Absicherung für Hausbesitzer und trägt dazu bei, dass auch größere Schäden durch Naturereignisse oder technische Defekte abgedeckt werden können.</Typography>
                    </Stack>
                
                </div>
            </div>

            <ToRechner/>
        </div>
        <Footer/>
        <STT/>
        </div>
    );

    }

    else if(!isBig){

        return(
            <div id="header">
            <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Elementarschäden</title>
                    <meta name="description" content="Elementarschäden: Warum diese in der Wohngebäudeversicherung mitversichert werden sollten."/>
                    <meta name="keywords" content="Testsieger Wohngebäudeversicherung, Wohngebäudeversicherung Glas, Wohngebäudeversicherung Elementar, Wohngebäudeversicherung Blitzschlag, Wohngebäudeversicherung Rückstau, Wohngebäudeversicherung Erfahrungen" />


                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>
    
                    <Typography sx={{fontSize: "15vw",justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Elementar</Typography>
    
                </div>
                <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                    <div style={{width: "80vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                        <Stack direction="column" spacing={5}>
                            <Typography sx={{fontSize:  "5vw",}}>Die Mitversicherung von Elementarschäden in der Wohngebäudeversicherung ist ein wichtiger Aspekt, den Hausbesitzer bei der Auswahl einer passenden Versicherung berücksichtigen sollten. Elementarschäden können durch Naturereignisse wie Hochwasser, Überschwemmungen, Erdrutsche, Lawinen oder auch durch Schneedruck oder Sturmfluten verursacht werden. Diese Schäden können erhebliche finanzielle Belastungen darstellen und eine wichtige Absicherung ist hierbei unverzichtbar.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Eine Wohngebäudeversicherung deckt in der Regel Schäden am eigentlichen Wohngebäude ab. Im Rahmen der Mitversicherung von Elementarschäden werden jedoch auch Schäden abgedeckt, die durch extreme Witterungsbedingungen oder andere Naturkatastrophen verursacht werden.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Die Kosten für die Versicherung von Elementarschäden hängen von verschiedenen Faktoren ab, wie z.B. der Lage des Wohngebäudes und der Wahrscheinlichkeit von Naturkatastrophen in der Region. Die Versicherungsprämie kann hierbei höher ausfallen als bei einer reinen Wohngebäudeversicherung. Es ist daher ratsam, verschiedene Angebote von Versicherungsunternehmen zu vergleichen, um das beste Preis-Leistungs-Verhältnis zu finden.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Wichtig zu beachten ist, dass die Mitversicherung von Elementarschäden in der Wohngebäudeversicherung nicht automatisch im Versicherungsumfang enthalten ist. Hausbesitzer sollten daher prüfen, ob diese Absicherung im Vertrag enthalten ist oder ob sie als zusätzliche Klausel ergänzt werden muss.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Im Falle eines Schadens durch einen Elementarschaden kann die Wohngebäudeversicherung den finanziellen Schaden abdecken. Hierbei kann es sich um Reparaturkosten oder auch um den Wiederbeschaffungswert des Wohngebäudes handeln.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Insgesamt bietet die Mitversicherung von Elementarschäden in der Wohngebäudeversicherung eine wichtige Absicherung für Hausbesitzer und trägt dazu bei, dass auch größere Schäden durch Naturereignisse oder technische Defekte abgedeckt werden können.</Typography>
                        </Stack>
                    
                    </div>
                </div>
    
                <ToRechnerMob/>
            </div>
            <FooterMob/>
            <STTMob/>
            </div>
        );

    }
}