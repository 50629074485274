import { Stack, Typography } from "@mui/material";



export default function HeaderHomeMob(){
    return(
        
            <div style={{ justifySelf: "center",  alignSelf: "center", display: "flex", height: "100vh",  }}>
            
                    <Stack direction="column" sx={{justifyContent: "center", alignContent: "center", display: "flex"}} spacing={2}>
                        <Typography  textAlign="center" sx={{color: "#ffffff", fontWeight: "bold", fontFamily: 'Plus Jakarta Sans, sans-serif', fontSize: "10vw"}}>Wohngebäude <br/> Versicherungen</Typography>
                        <Typography  textAlign="center" sx={{color: "#ffffff", fontWeight: "bold", fontFamily: 'Plus Jakarta Sans, sans-serif',  fontSize: "4vw"}}>Vergleichen leicht gemacht</Typography>
                        
                    </Stack>
            </div>
 
        
    );
}