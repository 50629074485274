import { Box, Card, CardContent,  Stack, Typography } from "@mui/material";

import GL_Fam  from "../../assets/WEBP/gl_fam.webp";
import ErweiterbarImage from  "../../assets/WEBP/erweiterbar.webp";




export default function HomeContent(){
    return(
        <div style={{justifyContent: "center", alignContent: "center", display: "flex"}}>
            <div style={{width: "80vw", }}>
                <Stack  direction="column" spacing={15} sx={{justifyItems:  "center", alignItems: "center", display:  "flex"}} >
                    <Stack  direction="column"  sx={{color: "#ffffff",  }}>
                        <Typography variant="h2" textAlign="center" sx={{fontWeight:  "bold"}}>Gebäude-Versicherungen</Typography>
                        
                        <Typography   variant="h4" sx={{fontWeight:  "bold",  textAlign: "left"}} mt={10}  >Was ist  eine Wohngebäude Versicherung?</Typography>
                        <div style={{justifyContent:  "center", alignContent: "center", display:  "flex", marginTop: 10}}>
                            <Typography width="60vw"  sx={{fontSize:  "1.2rem", textAlign:  "left" }}>Eine Wohngebäudeversicherung ist eine Versicherung, die Ihr Eigenheim und die darin befindlichen Gebäudeteile gegen Schäden durch bestimmte Risiken absichert. Diese Versicherung ist insbesondere für Hausbesitzer von großer Bedeutung, da sie eine finanzielle Absicherung gegen Schäden bietet, die durch unvorhersehbare Ereignisse verursacht werden können.</Typography>
                        </div>
                    </Stack>
                    <Stack direction="row" spacing={5} sx={{justifyContent:  "center", alignContent: "center", display:  "flex"}} >
                        <Card component={Box} sx={{width: 550, height: 300, borderRadius:  2, background: `linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('${GL_Fam}')`, backgroundRepeat: "no-repeat", backgroundSize:  "cover", justifyContent: "center", alignContent: "center", display:"flex"}} elevation={6}>
                            
                            <CardContent sx={{justifyContent: "center", alignItems: "center", display: "flex", width:  "auto"}}>
                                <Typography variant="h3" sx={{justifySelf: "center", alignSelf: "center", display: "flex", color: "#ffffff",  fontWeight: "bold",  }}>Abdeckung?</Typography>
                            </CardContent>

                            
                        </Card>
                        
                            <Typography sx={{fontSize:  "1.2rem", alignSelf: "center", color: "#ffffff", width: "42%",  justifySelf:  "left",display: "flex", fontFamily: 'Plus Jakarta Sans, sans-serif'}}>
                                Die Wohngebäudeversicherung deckt in der Regel Schäden ab, die durch Feuer, Sturm, Hagel, Blitzschlag, Leitungswasser, Rohrbruch und weitere Elementarschäden verursacht werden können. Dabei wird nicht nur das eigentliche Gebäude versichert, sondern auch die fest verbauten Gegenstände wie beispielsweise die Heizungsanlage oder Sanitäranlagen.
                            </Typography>
                        
                    </Stack>
                    <Stack direction="row" spacing={5} sx={{justifyContent:  "center", alignContent: "center", display:  "flex"}}>
                        <Typography sx={{fontSize:  "1.2rem",  alignSelf: "center", color: "#ffffff", width: "42%", fontFamily: 'Plus Jakarta Sans, sans-serif'}}>
                            Eine Wohngebäudeversicherung kann je nach Bedarf um weitere Bausteine erweitert werden, um zusätzlichen Schutz gegen Risiken wie z.B. Naturkatastrophen, Einbruch, Vandalismus oder Glasbruch zu bieten. Eine weitere wichtige Option ist die Elementarschadenversicherung, die vor Schäden durch Naturereignisse wie Überschwemmungen oder Erdrutsche schützt.                        </Typography>
                        
                        <Card component={Box} sx={{width: 550,height: 300,  borderRadius: 2, background: `linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('${ErweiterbarImage}')`, backgroundRepeat: "no-repeat", backgroundSize:  "cover", justifyContent: "center", alignContent: "center", display:"flex"}} elevation={6}>
                            <CardContent sx={{justifyContent: "center", alignItems: "center", display: "flex", width:  "auto"}}>
                                <Typography variant="h3" sx={{justifySelf: "center", alignSelf: "center", display: "flex", color: "#ffffff",  fontWeight: "bold"}}>Erweiterbar?</Typography>
                            </CardContent>
                            
                        </Card>
                        
                    </Stack>

                     

                </Stack>

                
                   
            </div>
            
        </div>
    );
}