import { Box, Card, CssBaseline, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import  startImage from "../../src/assets/WEBP/vg_rechner.webp";
import startImageHandy from "../assets/WEBP/vg_rechner_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import { Helmet } from 'react-helmet';


export  default function RechnerSite(){

    const isBig  = useMediaQuery('(min-width:600px)');
    
    if(isBig){

    return(
        <div id="header" style={{minHeight:  "auto", backgroundColor:  "#1a1b4b"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Vergleichsrechner</title>
                <meta name="keywords" content="Wohngebäudeversicherung Vergleichsrechner, Wohngebäudeversicherung Vergleich, Rechner, Versicherungsvergleich" />

                <script type="text/javascript">
                    var mrmoScrollToViewport = 1; /* scroll die Website zum Anfang des iFrames */
                    var mrmoScrollToViewportOffset = -20; /* Versatz beim scrollen zum Anfang des iFrames, individuell je Website */
                </script>
                <script type="text/javascript" src="https://www.mr-money.de/iframeControls.js"></script>
            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(${startImage})`,  backgroundRepeat:  "no-repeat", backgroundSize:  "cover", justifyContent: "center", alignContent: "center",display:"flex"}}>
                <Typography variant="h1"  sx={{fontWeight: "bold", color: "#ffffff",  textAlign:  "center",  justifySelf:"center",  alignSelf: "center", display:  "flex"}}>Vergleichsrechner</Typography>
                {/*<Divider flexItem orientation="horizontal" sx={{borderBottomWidth: "1.8rem", width:  790, marginLeft:  70, marginRight: 70 , background: "#f7a400", marginTop: -3.5}}/>*/}
            </div>
            <div style={{paddingTop: 100, paddingBottom:  100, justifyContent:  "center", alignContent:  "center", display: "flex"}}>

                
                <Card component={Box} sx={{width:  "auto",  height: "auto", padding:  10,  borderRadius: 2}} elevation={24}>
                    <iframe id="mrmoFrame" scrolling="no" border="0" style={{border: "0",justifySelf:  "center", alignSelf: "center",display: "flex",   }} src="https://www.mr-money.de/cookievgl.php?sp=wg&id=mrmo&subid=wgv" width="900"></iframe>
                    
                    
                </Card>
                

            </div>
            <Footer/>
            <STT/>
        </div>
    );

    }

    else if(!isBig){
        
        return(
            <div id="header" style={{minHeight:  "auto", backgroundColor:  "#1a1b4b"}}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Vergleichsrechner</title>
                    <meta name="keywords" content="Wohngebäudeversicherung Vergleichsrechner, Wohngebäudeversicherung Vergleich, Rechner, Versicherungsvergleich" />

                    <script type="text/javascript">
                    var mrmoScrollToViewport = 1; /* scroll die Website zum Anfang des iFrames */
                    var mrmoScrollToViewportOffset = -20; /* Versatz beim scrollen zum Anfang des iFrames, individuell je Website */
                    </script>
                    <script type="text/javascript" src="https://www.mr-money.de/iframeControls.js"></script>
                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)), url(${startImageHandy})`,  backgroundRepeat:  "no-repeat", backgroundSize:  "cover", justifyContent: "center", alignContent: "center",display:"flex"}}>
                    <Typography  sx={{fontSize: "15vw",fontWeight: "bold", color: "#ffffff",  textAlign:  "center",  justifySelf:"center",  alignSelf: "center", display:  "flex"}}>Vergleichs-<br/>rechner</Typography>
                    {/*<Divider flexItem orientation="horizontal" sx={{borderBottomWidth: "1.8rem", width:  790, marginLeft:  70, marginRight: 70 , background: "#f7a400", marginTop: -3.5}}/>*/}
                </div>
                <div style={{paddingTop: 100, paddingBottom:  100, justifyContent:  "center", alignContent:  "center", display: "flex"}}>
    
                    
                    <Card component={Box} sx={{width:  "90vw",  height: "auto", padding:  2,  borderRadius: 2}} elevation={24}>
                        <iframe id="mrmoFrame" scrolling="no" border="0" style={{border: "0",justifySelf:  "center", alignSelf: "center",display: "flex",    }} src="https://www.mr-money.de/cookievgl.php?sp=wg&id=mrmo&subid=wgv" width="100%"></iframe>
                        <script type="text/javascript">
                            var mrmoScrollToViewport = 1; /* scroll die Website zum Anfang des iFrames */
                            var mrmoScrollToViewportOffset = -20; /* Versatz beim scrollen zum Anfang des iFrames, individuell je Website */
                        </script>
                        <script type="text/javascript" src="https://www.mr-money.de/iframeControls.js"></script>
                        </Card>
                    
    
                </div>
                <FooterMob/>
                <STTMob/>
            </div>
        );


    }
}