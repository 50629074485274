
import { CssBaseline, List, ListItem, Stack, Typography, useMediaQuery } from "@mui/material";
import startImage from "../assets/WEBP/faq_image5.webp";
import startImageHandy from "../assets/WEBP/faq_image5_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import ToRechner from "../components/RLE/RLE";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import { Helmet } from 'react-helmet';


export default function  WFSite(){

    const isBig  = useMediaQuery('(min-width:600px)');
    
    if(isBig){

    return(
        <div id="header">
        <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Wohnfläche</title>
                <meta name="description" content="Was zählt alles zur Wohnfläche"/>
                <meta name="keywords" content="Wohngebäudeversicherung Wonfläche, Wohnfläche Wohngebäude, Wohngebäudeversicherung" />

            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>

                <Typography variant="h1" sx={{justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Wohnfläche</Typography>

            </div>
            <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                <div style={{width: "60vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                    <Stack direction="column" spacing={5}>
                    <Typography sx={{fontSize:  "1.2rem",}}>Die Wohnfläche spielt bei der Wohngebäudeversicherung eine wichtige Rolle, da sie als Basis für die Berechnung der Versicherungsprämie dient. Im Folgenden soll erläutert werden, welche Bedeutung die Wohnfläche hat und welche Aspekte dabei zu beachten sind.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Die Wohnfläche umfasst in der Regel die gesamte beheizte Fläche des Gebäudes, also Wohn- und Nutzflächen. Dazu zählen beispielsweise Wohnzimmer, Schlafzimmer, Küche und Badezimmer, aber auch Keller-, Dach- und Spitzbodenräume sowie Balkone und Terrassen. Nicht zur Wohnfläche zählen hingegen beispielsweise Garagen, Carports und Gartenhäuser. Diese können jedoch unter Umständen gesondert versichert werden.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Da die Wohnfläche die Basis für die Berechnung der Versicherungsprämie bildet, ist es wichtig, die Fläche des Gebäudes korrekt zu ermitteln. Hierfür können unterschiedliche Methoden verwendet werden, beispielsweise das Aufmaß durch einen Architekten oder eine Vermessung des Grundstücks. Alternativ kann auch auf vorhandene Pläne und Unterlagen zurückgegriffen werden. Wichtig ist, dass die ermittelte Fläche möglichst genau und plausibel ist, um im Schadensfall nicht unter- oder überversichert zu sein.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Je nach Versicherer können auch unterschiedliche Wohnflächenberechnungen zugrunde gelegt werden. So gibt es beispielsweise die DIN 277-Norm, die eine einheitliche Berechnung der Wohnfläche ermöglicht. Andere Versicherer wiederum setzen auf eigene Berechnungsmethoden oder eine Kombination aus verschiedenen Verfahren. Es ist daher ratsam, sich vor Abschluss einer Wohngebäudeversicherung über die genaue Wohnflächenberechnung des Versicherers zu informieren.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Wichtig zu beachten ist auch, dass sich die Wohnfläche im Laufe der Zeit verändern kann. So können beispielsweise durch Umbauten oder Anbauten zusätzliche Wohnflächen entstehen, die in der Versicherung berücksichtigt werden müssen. Auch eine nachträgliche Aufstockung des Hauses kann die Wohnfläche vergrößern. In diesen Fällen ist es wichtig, die Versicherung zeitnah über die Veränderungen zu informieren, um eine Anpassung der Versicherungsprämie und des Versicherungsschutzes vorzunehmen.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Insgesamt spielt die Wohnfläche also eine wichtige Rolle bei der Wohngebäudeversicherung. Eine korrekte Ermittlung der Fläche ist dabei unerlässlich, um im Schadensfall ausreichend versichert zu sein. Auch Veränderungen der Wohnfläche sollten zeitnah mitgeteilt werden, um eine Anpassung des Versicherungsschutzes vorzunehmen. Wer unsicher ist, wie die Wohnfläche korrekt ermittelt wird oder welche Veränderungen gemeldet werden müssen, sollte sich an einen Versicherungsexperten wenden.</Typography>
                    </Stack>
                
                </div>
            </div>

            <ToRechner/>

        </div>
        <Footer/>
        <STT/>
        </div>
    );

    }

    else if(!isBig){

        return(
            <div id="header">
            <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Wohnfläche</title>
                    <meta name="description" content="Was zählt alles zur Wohnfläche"/>
                    <meta name="keywords" content="Wohngebäudeversicherung Wonfläche, Wohnfläche Wohngebäude, Wohngebäudeversicherung" />

                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>
    
                    <Typography sx={{fontSize: "15vw",justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Wohnfläche</Typography>
    
                </div>
                <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                    <div style={{width: "80vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                        <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "5vw",}}>Die Wohnfläche spielt bei der Wohngebäudeversicherung eine wichtige Rolle, da sie als Basis für die Berechnung der Versicherungsprämie dient. Im Folgenden soll erläutert werden, welche Bedeutung die Wohnfläche hat und welche Aspekte dabei zu beachten sind.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Die Wohnfläche umfasst in der Regel die gesamte beheizte Fläche des Gebäudes, also Wohn- und Nutzflächen. Dazu zählen beispielsweise Wohnzimmer, Schlafzimmer, Küche und Badezimmer, aber auch Keller-, Dach- und Spitzbodenräume sowie Balkone und Terrassen. Nicht zur Wohnfläche zählen hingegen beispielsweise Garagen, Carports und Gartenhäuser. Diese können jedoch unter Umständen gesondert versichert werden.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Da die Wohnfläche die Basis für die Berechnung der Versicherungsprämie bildet, ist es wichtig, die Fläche des Gebäudes korrekt zu ermitteln. Hierfür können unterschiedliche Methoden verwendet werden, beispielsweise das Aufmaß durch einen Architekten oder eine Vermessung des Grundstücks. Alternativ kann auch auf vorhandene Pläne und Unterlagen zurückgegriffen werden. Wichtig ist, dass die ermittelte Fläche möglichst genau und plausibel ist, um im Schadensfall nicht unter- oder überversichert zu sein.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Je nach Versicherer können auch unterschiedliche Wohnflächenberechnungen zugrunde gelegt werden. So gibt es beispielsweise die DIN 277-Norm, die eine einheitliche Berechnung der Wohnfläche ermöglicht. Andere Versicherer wiederum setzen auf eigene Berechnungsmethoden oder eine Kombination aus verschiedenen Verfahren. Es ist daher ratsam, sich vor Abschluss einer Wohngebäudeversicherung über die genaue Wohnflächenberechnung des Versicherers zu informieren.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Wichtig zu beachten ist auch, dass sich die Wohnfläche im Laufe der Zeit verändern kann. So können beispielsweise durch Umbauten oder Anbauten zusätzliche Wohnflächen entstehen, die in der Versicherung berücksichtigt werden müssen. Auch eine nachträgliche Aufstockung des Hauses kann die Wohnfläche vergrößern. In diesen Fällen ist es wichtig, die Versicherung zeitnah über die Veränderungen zu informieren, um eine Anpassung der Versicherungsprämie und des Versicherungsschutzes vorzunehmen.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Insgesamt spielt die Wohnfläche also eine wichtige Rolle bei der Wohngebäudeversicherung. Eine korrekte Ermittlung der Fläche ist dabei unerlässlich, um im Schadensfall ausreichend versichert zu sein. Auch Veränderungen der Wohnfläche sollten zeitnah mitgeteilt werden, um eine Anpassung des Versicherungsschutzes vorzunehmen. Wer unsicher ist, wie die Wohnfläche korrekt ermittelt wird oder welche Veränderungen gemeldet werden müssen, sollte sich an einen Versicherungsexperten wenden.</Typography>
                        </Stack>
                    
                    </div>
                </div>
    
                <ToRechnerMob/>
    
            </div>
            <FooterMob/>
            <STTMob/>
            </div>
        );

    }
}