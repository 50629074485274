import { Box, Card, CardContent, Stack, Typography } from "@mui/material";

import GL_Fam  from "../../../assets/WEBP/gl_fam.webp";
import ErweiterbarImage from  "../../../assets/WEBP/erweiterbar.webp";




export default function HomeContentMob(){
    return(
        <div style={{justifyContent: "center", alignContent: "center", display: "flex"}}>
            <div style={{width: "80vw", justifyContent:  "center", alignContent: "center", display:  "flex"}}>
                <Stack  direction="column" spacing={8}  width="80vw">
                    <Stack  direction="column"  sx={{color: "#ffffff",  }}>
                        <Typography  textAlign="center" sx={{fontSize: "9vw", fontWeight:  "bold",fontFamily: 'Plus Jakarta Sans, sans-serif', justifySelf: "center", alignSelf: "center", display: "flex"}}>Gebäude<br/>Versicherungen</Typography>
                        
                        <Typography p={1} sx={{fontWeight:  "bold",  fontSize:  "5.5vw"}} mt={5}  >Was ist  eine Wohngebäude Versicherung?</Typography>
                        <div style={{justifyContent:  "center", alignContent: "center", display:  "flex", marginTop: 5}}>
                            <Typography p={1}  sx={{fontSize:  "4vw", fontFamily: 'Plus Jakarta Sans, sans-serif' }}>Eine Wohngebäudeversicherung ist eine Versicherung, die Ihr Eigenheim und die darin befindlichen Gebäudeteile gegen Schäden durch bestimmte Risiken absichert. Diese Versicherung ist insbesondere für Hausbesitzer von großer Bedeutung, da sie eine finanzielle Absicherung gegen Schäden bietet, die durch unvorhersehbare Ereignisse verursacht werden können.</Typography>
                        </div>
                    </Stack>
                    <Stack direction="column" spacing={3} >
                        <Card component={Box} sx={{width: "auto", height: 300, borderRadius:  2, background: `linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('${GL_Fam}')`, backgroundRepeat: "no-repeat", backgroundSize:  "cover"}} elevation={6}>
                            
                            <CardContent  sx={{justifyContent: "center", alignItems: "center", display: "flex", width:  "auto", height: 300}}>
                                <Typography  sx={{justifySelf: "center", alignSelf: "center", display: "flex", color: "#ffffff",  fontWeight: "bold", fontSize: "8vw" }}>Abdeckung?</Typography>
                            </CardContent>

                            
                        </Card>
                        
                            <Typography p={1} sx={{fontSize:  "4vw", alignSelf: "center", color: "#ffffff", justifySelf:  "left",display: "flex", fontFamily: 'Plus Jakarta Sans, sans-serif'}}>
                                Die Wohngebäudeversicherung deckt in der Regel Schäden ab, die durch Feuer, Sturm, Hagel, Blitzschlag, Leitungswasser, Rohrbruch und weitere Elementarschäden verursacht werden können. Dabei wird nicht nur das eigentliche Gebäude versichert, sondern auch die fest verbauten Gegenstände wie beispielsweise die Heizungsanlage oder Sanitäranlagen.
                            </Typography>
                        
                    </Stack>
                    <Stack direction="column" spacing={3} >
                        
                        <Card component={Box} sx={{width: "auto",height: 300,  borderRadius: 2, background: `linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('${ErweiterbarImage}')`, backgroundRepeat: "no-repeat", backgroundSize:  "cover"}} elevation={6}>
                            <CardContent sx={{justifyContent: "center", alignItems: "center", display: "flex", width: "auto", height: 300}}>
                                <Typography  sx={{fontSize:  "8vw", justifySelf: "center", alignSelf: "center", display: "flex", color: "#ffffff",  fontWeight: "bold"}}>Erweiterbar?</Typography>
                            </CardContent>
                            
                        </Card>

                        <Typography p={1} sx={{fontSize:  "4vw",  alignSelf: "center", color: "#ffffff", fontFamily: 'Plus Jakarta Sans, sans-serif'}}>
                            Eine Wohngebäudeversicherung kann je nach Bedarf um weitere Bausteine erweitert werden, um zusätzlichen Schutz gegen Risiken wie z.B. Naturkatastrophen, Einbruch, Vandalismus oder Glasbruch zu bieten. Eine weitere wichtige Option ist die Elementarschadenversicherung, die vor Schäden durch Naturereignisse wie Überschwemmungen oder Erdrutsche schützt.                        </Typography>
                        
                        
                    </Stack>

                     

                </Stack>

                
                   
            </div>
            
        </div>
    );
}