import { Box, Fab, Fade, useScrollTrigger } from "@mui/material";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {Link as ScrollLink}  from "react-scroll";


export default function STTMob(){

    const trigger  =  useScrollTrigger({disableHysteresis: true,  threshold: 100});


    return(
        <Fade in={trigger}>
            
            <Box
                
                role="presentation"
                sx={{position: "fixed",  bottom: 20,  right: 20}}>
                    <ScrollLink 
                        to="header"
                        href="#header"
                        spy={true}
                        duration={1500}
                        smooth={true}>
                            <Fab size="small" aria-label="Zum Anfang"  sx={{backgroundColor: "#ffffff"}}>
                                <ExpandLessIcon sx={{fontSize: "2rem",}}/>
                            </Fab>
                    </ScrollLink>
                    
                </Box>

        </Fade>
    );
}