import { CssBaseline, List, ListItem, Stack, Typography, useMediaQuery } from "@mui/material";
import startImage from "../assets/WEBP/faq_image5.webp";
import startImageHandy from "../assets/WEBP/faq_image5_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import ToRechner from "../components/RLE/RLE";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import { Helmet } from 'react-helmet';


export default function  VMSite(){

    const isBig  = useMediaQuery('(min-width:600px)');
    
    if(isBig){

    return(
        <div id="header">
        <div style={{minHeight: "160vh",  backgroundColor: "#1a1b4b"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Vermieter</title>
                <meta name="description" content="Was gilt es, als Vermieter zu beachten"/>
                <meta name="keywords" content="Wohngebäudeversicherung Vermieter, Vermieter, Wohngebäudeversicherung" />


            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>

                <Typography variant="h1" sx={{justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Vermieter</Typography>

            </div>
            <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                <div style={{width: "60vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                    <Stack direction="column" spacing={5}>
                    <Typography sx={{fontSize:  "1.2rem",}}>Als Vermieter von Wohnraum ist es wichtig, eine Wohngebäudeversicherung abzuschließen, um das eigene Eigentum abzusichern. Dabei gibt es jedoch einige wichtige Aspekte, die Vermieter beachten sollten:</Typography>

                    <List>
                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem", fontWeight:  "bold",  color: "#f7a400"}}>1.</span> <span style={{fontWeight: "bold"}}>Deckungsumfang:</span> Vermieter sollten darauf achten, dass der Deckungsumfang der Wohngebäudeversicherung ausreichend ist, um alle Schäden am Gebäude, inklusive der Außenanlagen, abzudecken. Hierbei sollten auch Elementarschäden wie Überschwemmungen oder Erdbeben berücksichtigt werden.</Typography>
                        </ListItem>

                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem", fontWeight:  "bold", color: "#f7a400"}}>2.</span> <span style={{fontWeight: "bold"}}>Mietausfallversicherung:</span>Es kann sinnvoll sein, eine Mietausfallversicherung in die Wohngebäudeversicherung aufzunehmen. Diese deckt den finanziellen Ausfall ab, falls der Vermieter aufgrund eines Schadens am Gebäude vorübergehend keine Mieteinnahmen erzielen kann. </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem", fontWeight:  "bold", color: "#f7a400"}}>3.</span> <span style={{fontWeight: "bold"}}>Versicherung von Einbauten:</span>Wenn der Vermieter das Mietobjekt möbliert vermietet oder bestimmte Einbauten wie Einbauküchen, Regale oder Lampen bereitstellt, sollte darauf geachtet werden, dass diese in der Wohngebäudeversicherung mitversichert sind.</Typography>
                        </ListItem>

                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem", fontWeight:  "bold",  color: "#f7a400"}}>4.</span> <span style={{fontWeight: "bold"}}>Haftpflichtversicherung:</span>Eine Haftpflichtversicherung für Vermieter kann ebenfalls sinnvoll sein. Diese schützt den Vermieter vor Ansprüchen von Mietern oder Dritten, die aufgrund von Schäden am Gebäude entstanden sind.  </Typography>
                        </ListItem>

                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem",fontWeight:  "bold", color: "#f7a400"}}>5.</span> <span style={{fontWeight: "bold"}}>Regelmäßige Überprüfung:</span>Es ist ratsam, die Wohngebäudeversicherung regelmäßig zu überprüfen und gegebenenfalls anzupassen. Insbesondere bei Änderungen am Gebäude wie Renovierungen oder Anbauten sollten Vermieter sicherstellen, dass die Versicherung noch ausreichenden Schutz bietet.</Typography>
                        </ListItem>

                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem",fontWeight:  "bold", color: "#f7a400"}}>6.</span> <span style={{fontWeight: "bold"}}>Eintragung im Mietvertrag:</span> Vermieter sollten im Mietvertrag deutlich machen, dass sie eine Wohngebäudeversicherung abgeschlossen haben und welche Schäden diese abdeckt. Dadurch können Missverständnisse vermieden und die Haftung des Vermieters begrenzt werden.</Typography>
                        </ListItem>
                    </List>

                    <Typography sx={{fontSize:  "1.2rem",}}>Es ist wichtig zu beachten, dass nicht alle Wohngebäudeversicherungen alle oben genannten Schäden abdecken. Daher ist es ratsam, bei Vertragsabschluss darauf zu achten, welche Schäden im Versicherungsumfang enthalten sind. Auch kann es sein, dass einige Schäden nur gegen einen zusätzlichen Aufpreis versichert werden können. Es lohnt sich also, verschiedene Angebote von Versicherungsunternehmen zu vergleichen und sorgfältig abzuwägen, welche Versicherung am besten zu den individuellen Bedürfnissen passt.</Typography>
                    </Stack>
                
                </div>
            </div>

            <ToRechner/>

        </div>
        <Footer/>
        <STT/>
        </div>
    );

    }

    else if(!isBig){

        return(
            <div id="header">
            <div style={{minHeight: "160vh",  backgroundColor: "#1a1b4b"}}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Vermieter</title>
                    <meta name="description" content="Was gilt es, als Vermieter zu beachten"/>
                    <meta name="keywords" content="Wohngebäudeversicherung Vermieter, Vermieter, Wohngebäudeversicherung" />


                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>
    
                    <Typography sx={{fontSize: "15vw",justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Vermieter</Typography>
    
                </div>
                <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                    <div style={{width: "80vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                        <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "5vw",}}>Als Vermieter von Wohnraum ist es wichtig, eine Wohngebäudeversicherung abzuschließen, um das eigene Eigentum abzusichern. Dabei gibt es jedoch einige wichtige Aspekte, die Vermieter beachten sollten:</Typography>
    
                        <List>
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw", fontWeight:  "bold",  color: "#f7a400"}}>1. </span> <br/><span style={{fontWeight: "bold"}}>Deckungsumfang:</span> Vermieter sollten darauf achten, dass der Deckungsumfang der Wohngebäudeversicherung ausreichend ist, um alle Schäden am Gebäude, inklusive der Außenanlagen, abzudecken. Hierbei sollten auch Elementarschäden wie Überschwemmungen oder Erdbeben berücksichtigt werden.</Typography>
                            </ListItem>
    
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw", fontWeight:  "bold", color: "#f7a400"}}>2. </span> <br/> <span style={{fontWeight: "bold"}}>Mietausfallversicherung:</span>Es kann sinnvoll sein, eine Mietausfallversicherung in die Wohngebäudeversicherung aufzunehmen. Diese deckt den finanziellen Ausfall ab, falls der Vermieter aufgrund eines Schadens am Gebäude vorübergehend keine Mieteinnahmen erzielen kann. </Typography>
                            </ListItem>
    
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw", fontWeight:  "bold", color: "#f7a400"}}>3. </span> <br/> <span style={{fontWeight: "bold"}}>Versicherung von Einbauten:</span>Wenn der Vermieter das Mietobjekt möbliert vermietet oder bestimmte Einbauten wie Einbauküchen, Regale oder Lampen bereitstellt, sollte darauf geachtet werden, dass diese in der Wohngebäudeversicherung mitversichert sind.</Typography>
                            </ListItem>
    
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw", fontWeight:  "bold",  color: "#f7a400"}}>4. </span> <br/> <span style={{fontWeight: "bold"}}>Haftpflichtversicherung:</span>Eine Haftpflichtversicherung für Vermieter kann ebenfalls sinnvoll sein. Diese schützt den Vermieter vor Ansprüchen von Mietern oder Dritten, die aufgrund von Schäden am Gebäude entstanden sind.  </Typography>
                            </ListItem>
    
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw",fontWeight:  "bold", color: "#f7a400"}}>5. </span> <br/> <span style={{fontWeight: "bold"}}>Regelmäßige Überprüfung:</span>Es ist ratsam, die Wohngebäudeversicherung regelmäßig zu überprüfen und gegebenenfalls anzupassen. Insbesondere bei Änderungen am Gebäude wie Renovierungen oder Anbauten sollten Vermieter sicherstellen, dass die Versicherung noch ausreichenden Schutz bietet.</Typography>
                            </ListItem>
    
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw",fontWeight:  "bold", color: "#f7a400"}}>6. </span> <br/> <span style={{fontWeight: "bold"}}>Eintragung im Mietvertrag:</span> Vermieter sollten im Mietvertrag deutlich machen, dass sie eine Wohngebäudeversicherung abgeschlossen haben und welche Schäden diese abdeckt. Dadurch können Missverständnisse vermieden und die Haftung des Vermieters begrenzt werden.</Typography>
                            </ListItem>
                        </List>
    
                        <Typography sx={{fontSize:  "5vw",}}>Es ist wichtig zu beachten, dass nicht alle Wohngebäudeversicherungen alle oben genannten Schäden abdecken. Daher ist es ratsam, bei Vertragsabschluss darauf zu achten, welche Schäden im Versicherungsumfang enthalten sind. Auch kann es sein, dass einige Schäden nur gegen einen zusätzlichen Aufpreis versichert werden können. Es lohnt sich also, verschiedene Angebote von Versicherungsunternehmen zu vergleichen und sorgfältig abzuwägen, welche Versicherung am besten zu den individuellen Bedürfnissen passt.</Typography>
                        </Stack>
                    
                    </div>
                </div>
    
                <ToRechnerMob/>
    
            </div>
            <FooterMob/>
            <STTMob/>
            </div>
        );


    }
}