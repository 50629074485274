
import { CssBaseline, Stack, Typography, useMediaQuery } from "@mui/material";
import startImage from "../assets/WEBP/faq_image5.webp";
import startImageHandy from "../assets/WEBP/faq_image5_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import ToRechner from "../components/RLE/RLE";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import { Helmet } from 'react-helmet';


export default function  EWGSite(){

    const isBig  = useMediaQuery('(min-width:600px)');

    if(isBig){

    return(
        <div id="header">
        <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Erfahrungen</title>
                <meta name="description" content="Profitieren Sie von unseren Erfahrungen bei den Wohngebäudeversicherern"/>
                <meta name="keywords" content="Testsieger Wohngebäudeversicherung, Wohngebäudeversicherung Glas, Wohngebäudeversicherung Elementar, Wohngebäudeversicherung Blitzschlag, Wohngebäudeversicherung Rückstau, Wohngebäudeversicherung Erfahrungen" />

            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>

                <Typography variant="h1" sx={{justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Erfahrung WG</Typography>

            </div>
            <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                <div style={{width: "60vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                    <Stack direction="column" spacing={5}>
                    <Typography sx={{fontSize:  "1.2rem",}}>Die Wohngebäudeversicherung ist eine wichtige Absicherung für Hausbesitzer, die vor finanziellen Folgen von Schäden an ihrem Wohngebäude schützen soll. Doch wie zufrieden sind die Kunden eigentlich mit ihrer Wohngebäudeversicherung? In diesem Text sollen Erfahrungen von Kunden mit der Wohngebäudeversicherung zusammengetragen werden.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Generell fällt auf, dass Kunden mit ihrer Wohngebäudeversicherung meistens zufrieden sind. So berichten viele Kunden, dass ihnen im Schadensfall schnell und unkompliziert geholfen wurde. Die Schadensabwicklung verlief in vielen Fällen reibungslos und die Versicherung übernahm die entstandenen Kosten ohne Probleme. Auch die Beratung durch den Versicherer im Vorfeld wurde von vielen Kunden als positiv bewertet.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Allerdings gibt es auch negative Erfahrungen von Kunden mit der Wohngebäudeversicherung. Ein häufiges Problem ist die Bewertung der Schäden durch den Versicherer. Kunden berichten, dass die Versicherung bei Schäden oft nur einen Teil der Kosten übernahm oder den Schaden gar nicht erst anerkannte. In einigen Fällen kam es auch zu Streitigkeiten zwischen dem Versicherungsnehmer und der Versicherung.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Ein weiteres Thema, das von Kunden oft kritisiert wird, ist die Höhe der Versicherungsprämie. Viele Kunden empfinden die Prämien als zu hoch und wünschen sich eine günstigere Versicherungslösung. Allerdings sollte hierbei bedacht werden, dass die Höhe der Prämie von verschiedenen Faktoren wie beispielsweise der Lage des Hauses, der Bauweise und dem Umfang der Versicherungsleistungen abhängt.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Ein weiterer Aspekt, der bei Kunden auf Kritik stößt, ist die Vertragslaufzeit der Wohngebäudeversicherung. Viele Kunden fühlen sich an lange Vertragslaufzeiten gebunden und wünschen sich eine flexiblere Lösung. Allerdings ist es auch hier wichtig zu beachten, dass langfristige Verträge oft günstigere Prämien und bessere Konditionen bieten.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Abschließend lässt sich sagen, dass Kunden mit ihrer Wohngebäudeversicherung meistens zufrieden sind und im Schadensfall schnell und zuverlässig geholfen wird. Allerdings sollten Kunden bei der Wahl ihrer Wohngebäudeversicherung darauf achten, dass die Versicherung die individuellen Bedürfnisse abdeckt und die Konditionen fair sind. Ein Vergleich verschiedener Anbieter kann hierbei helfen, die passende Wohngebäudeversicherung zu finden.</Typography>
                    </Stack>
                    
                </div>
            </div>

            <ToRechner/>

        </div>
        <Footer/>
        <STT/>
        </div>
    );

    }
    else if(!isBig){

        return(
            <div id="header">
            <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Erfahrungen</title>
                    <meta name="description" content="Profitieren Sie von unseren Erfahrungen bei den Wohngebäudeversicherern"/>
                    <meta name="keywords" content="Testsieger Wohngebäudeversicherung, Wohngebäudeversicherung Glas, Wohngebäudeversicherung Elementar, Wohngebäudeversicherung Blitzschlag, Wohngebäudeversicherung Rückstau, Wohngebäudeversicherung Erfahrungen" />
                    
                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyItems: "center",  alignItems: "center", display: "flex"}}>
    
                    <Typography sx={{fontSize: "15vw", justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff", textAlign: "center"}}>Erfahrung WG</Typography>
    
                </div>
                <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                    <div style={{width: "80vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                        <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "5vw",}}>Die Wohngebäudeversicherung ist eine wichtige Absicherung für Hausbesitzer, die vor finanziellen Folgen von Schäden an ihrem Wohngebäude schützen soll. Doch wie zufrieden sind die Kunden eigentlich mit ihrer Wohngebäudeversicherung? In diesem Text sollen Erfahrungen von Kunden mit der Wohngebäudeversicherung zusammengetragen werden.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Generell fällt auf, dass Kunden mit ihrer Wohngebäudeversicherung meistens zufrieden sind. So berichten viele Kunden, dass ihnen im Schadensfall schnell und unkompliziert geholfen wurde. Die Schadensabwicklung verlief in vielen Fällen reibungslos und die Versicherung übernahm die entstandenen Kosten ohne Probleme. Auch die Beratung durch den Versicherer im Vorfeld wurde von vielen Kunden als positiv bewertet.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Allerdings gibt es auch negative Erfahrungen von Kunden mit der Wohngebäudeversicherung. Ein häufiges Problem ist die Bewertung der Schäden durch den Versicherer. Kunden berichten, dass die Versicherung bei Schäden oft nur einen Teil der Kosten übernahm oder den Schaden gar nicht erst anerkannte. In einigen Fällen kam es auch zu Streitigkeiten zwischen dem Versicherungsnehmer und der Versicherung.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Ein weiteres Thema, das von Kunden oft kritisiert wird, ist die Höhe der Versicherungsprämie. Viele Kunden empfinden die Prämien als zu hoch und wünschen sich eine günstigere Versicherungslösung. Allerdings sollte hierbei bedacht werden, dass die Höhe der Prämie von verschiedenen Faktoren wie beispielsweise der Lage des Hauses, der Bauweise und dem Umfang der Versicherungsleistungen abhängt.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Ein weiterer Aspekt, der bei Kunden auf Kritik stößt, ist die Vertragslaufzeit der Wohngebäudeversicherung. Viele Kunden fühlen sich an lange Vertragslaufzeiten gebunden und wünschen sich eine flexiblere Lösung. Allerdings ist es auch hier wichtig zu beachten, dass langfristige Verträge oft günstigere Prämien und bessere Konditionen bieten.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Abschließend lässt sich sagen, dass Kunden mit ihrer Wohngebäudeversicherung meistens zufrieden sind und im Schadensfall schnell und zuverlässig geholfen wird. Allerdings sollten Kunden bei der Wahl ihrer Wohngebäudeversicherung darauf achten, dass die Versicherung die individuellen Bedürfnisse abdeckt und die Konditionen fair sind. Ein Vergleich verschiedener Anbieter kann hierbei helfen, die passende Wohngebäudeversicherung zu finden.</Typography>
                        </Stack>
                        
                    </div>
                </div>
    
                <ToRechnerMob/>
    
            </div>
            <FooterMob/>
            <STTMob/>
            </div>
        );

    }
}