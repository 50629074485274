import { Box, Button, CssBaseline, Stack, Typography, useMediaQuery } from "@mui/material";
import Navbar from "../components/Navbar/Navbar";
import HeaderHome from "../components/Header_Home/HeaderHome";
import startImage from  "../assets/WEBP/house.webp";
import startImageHandy from  "../assets/WEBP/house_handy.webp";
import VertrauenDiv from "../components/Header_Home/Vertrauen";
import HomeContent from "../components/HomeContent/HomeContent";
import HomeContentBottom from "../components/HomeContent/HomeContentBottom";
import { Parallax } from 'react-parallax';
import para_img from "../assets/WEBP/para_img.webp";
import para_img_handy from "../assets/WEBP/para_img_handy.webp";

import Footer from "../components/Footer/Footer";

import STT from "../components/Scroller/STT";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import HeaderHomeMob from "../Mobile/components/Header_Home/HeaderHome";
import HomeContentMob from "../Mobile/components/HomeContent/HomeContent";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import HomeContentBottomMob from "../Mobile/components/HomeContent/HomeContentBottom";
import { Helmet } from "react-helmet";


export default function Home(){
    const isBig  = useMediaQuery('(min-width:600px)');
    

    if(isBig){
    return (
        <div id="header" style={{overflowX: "hidden"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Home</title>
                <meta name="description" content="Wohngebäudeversicherung.de: Schützen Sie Ihr Zuhause mit einer maßgeschneiderten Wohngebäudeversicherung. Erfahren Sie alles über Leistungen, Preise und Versicherungsanbieter. Sichern Sie Ihr Eigentum optimal ab!" />
                <meta name="keywords" content="Testsieger wohngebäudeversicherung, Wohngebäudeversicherung Glas, Wohngebäudeversicherung Glasbruch, Wohngebäudeversicherung Blitzschlag, Wohngebäudeversicherung Rückstau, Wohngebäudeversicherung Testsieger, Wohngebäudeversicherung Erfahrungen" />
            </Helmet>
            
            <CssBaseline/>
            <Navbar/>
        <div style={{minHeight: "500vh",backgroundColor: "#1a1b4b",  }}>
            <div style={{ backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat: "no-repeat", backgroundSize: "cover",}} >
            
                <div  style={{height: "85vh",justifyContent: "center", alignContent: "center", display: "flex", }}>
                    <HeaderHome/>
                </div>
            </div>
                <div style={{height: "auto"}}>
                    
                    
                    <VertrauenDiv/>
                    
                </div>

                    <Parallax blur={5} bgImage={para_img}  alt="" style={{marginTop:  0}} strength={200}>
                            <div style={{height: 450,   justifyContent: "center",   display:  "flex",  paddingTop: 80, paddingBottom: 80}}>
                                <Stack  direction="column" spacing="auto" sx={{justifyItems:  "center",  alignItems: "center", display:  "flex"}}>
                                    <Typography variant="h2" sx={{ textAlign:  "center",  alignSelf: "center", fontWeight: "bold", color: "#f7a400"}}>Mit unserem Vergleichsrechner <br/>finden Sie das beste Angebot</Typography>
                                    
                                    <a href="/vergleichsrechner" style={{textDecoration: "none",  color: "#ffffff"}} >
                                        <Button variant="contained"  component={Box} sx={{width: 350,  height: 80,  borderRadius: 0, fontWeight:  "bold",  fontSize: "1.2rem",  }}>Zum Vergleichsrechner</Button>
                                    </a>
                                </Stack>
                            </div>
                        </Parallax>
                <div style={{marginTop: 70, paddingBottom:  70}}>
                    <HomeContent/>

                    
                        

                    <HomeContentBottom/>
                    

                    
                </div>
            
            
        </div>
        <Footer/>
        <STT/>
        </div>
        
    );
    }






    else if(!isBig){
        return(
            <div id="header" style={{overflowX:  "hidden"}}>
            
                    <div style={{backgroundColor: "#1a1b4b", paddingBottom: 50 }}>
                        <Helmet>
                        <title>Wohngebäudeversicherung: Home</title>
                        <meta name="description" content="Wohngebäudeversicherung.de: Schützen Sie Ihr Zuhause mit einer maßgeschneiderten Wohngebäudeversicherung. Erfahren Sie alles über Leistungen, Preise und Versicherungsanbieter. Sichern Sie Ihr Eigentum optimal ab!" />
                        <meta name="keywords" content="Testsieger wohngebäudeversicherung, Wohngebäudeversicherung Glas, Wohngebäudeversicherung Glasbruch, Wohngebäudeversicherung Blitzschlag, Wohngebäudeversicherung Rückstau, Wohngebäudeversicherung Testsieger, Wohngebäudeversicherung Erfahrungen" />
                        </Helmet>
                    
                        <CssBaseline/>
                        <NavbarMob/>
                        <div style={{height: "100vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}} >
                        
                            <div  style={{justifyContent: "center", alignContent: "center", display: "flex"}}>
                                <HeaderHomeMob/>
                            </div>
                        </div>

                        <Parallax blur={5} bgImage={para_img_handy} alt="" loading="lazy" height="auto" style={{marginTop:  0}} strength={200}>
                                        <div style={{height: "auto",   justifyContent: "center", alignContent: "center",   display:  "flex",  paddingTop: 40, paddingBottom: 40}}>
                                            <Stack direction="column" spacing={5} sx={{justifyItems:  "center",  alignItems: "center", display:  "flex"}}>
                                                <Typography width="80vw"  sx={{ textAlign:  "center",  alignSelf: "center", fontWeight: "bold", color: "#f7a400",  fontSize: "8vw"}}>Mit unserem Vergleichsrechner <br/>finden Sie das beste Angebot</Typography>
                                                
                                                <a href="/vergleichsrechner" style={{textDecoration: "none",  color: "#ffffff"}}>
                                                    <Button variant="contained"  component={Box} sx={{width: "80vw",  height: 60,  borderRadius: 0, fontWeight:  "bold",  fontSize: "4vw",  }}>Zum Vergleichsrechner</Button>
                                                </a>
                                            </Stack>
                                        </div>
                                    </Parallax>
                            
                            <div style={{marginTop: 70}}>
                                <HomeContentMob/>

                                
                                    

                                <HomeContentBottomMob/>
                                

                                
                            </div>
                        
                        
                    </div>
        <FooterMob/>
        <STTMob/>
        </div>
        );
    }
}