import {Stack, Typography } from "@mui/material";



export default function HeaderHome(){
    return(
        
            <div style={{ justifySelf: "center",  alignSelf: "center", display: "flex", height: "85vh",  }}>
            
                    <Stack direction="column" sx={{justifyContent: "center", alignContent: "center", display: "flex"}} spacing={2}>
                        <Typography variant="h1" textAlign="center" sx={{color: "#ffffff", fontWeight: "bold", fontFamily: 'Plus Jakarta Sans, sans-serif', }}>Wohngebäude <br/> Versicherungen</Typography>
                        <Typography variant="h5" textAlign="center" sx={{color: "#ffffff", fontWeight: "bold", fontFamily: 'Plus Jakarta Sans, sans-serif'}}>Vergleichen leicht gemacht</Typography>
                        
                    </Stack>
            </div>
 
        
    );
}