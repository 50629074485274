import { Box, Button, Stack, Typography } from "@mui/material";
import { Parallax } from "react-parallax";
import para_img from "../../assets/WEBP/para_img.webp";


export default function ToRechner(){

    return(
        <Parallax blur={5} bgImage={para_img} alt="" loading="lazy"  style={{marginTop:  50}} strength={200}>
                            <div style={{height: 450,   justifyContent: "center",   display:  "flex",  paddingTop: 80, paddingBottom: 80}}>
                                <Stack  direction="column" spacing="auto" sx={{justifyItems:  "center",  alignItems: "center", display:  "flex"}}>
                                    <Typography variant="h2" sx={{ textAlign:  "center",  alignSelf: "center", fontWeight: "bold", color: "#f7a400"}}>Mit unserem Vergleichsrechner <br/>finden Sie das beste Angebot</Typography>
                                    
                                    <a href="/vergleichsrechner" >
                                        <Button variant="contained"  component={Box} sx={{width: 350,  height: 80,  borderRadius: 0, fontWeight:  "bold",  fontSize: "1.2rem",  }}>Zum Vergleichsrechner</Button>
                                    </a>
                                </Stack>
                            </div>
                        </Parallax>
    );
}