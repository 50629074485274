import { Box, Card, CardContent, CardMedia, Stack, Typography } from "@mui/material";

import geschuetzt  from "../../assets/WEBP/geschuetzt_n.webp";
import verpflichtend from  "../../assets/WEBP/verpflichtung_n.webp";
import  wissenswertes  from "../../assets/wissenswertes.png";
import  ht  from  "../../assets/WEBP/haeufige_themen.webp";
import  currentT from "../../assets/WEBP/currentT.webp";
import useful from  "../../assets/WEBP/useful.webp";


export default  function  HomeContentBottom(){

    return(

        <div  style={{}}>
        <div style={{justifyContent: "center", alignContent: "center", display: "flex", marginTop: 150, marginBottom:  100, }}>
            <div style={{width: "80vw", justifyContent:  "center", alignContent: "center", display:  "flex"}}>
                <Stack  direction="column" spacing={15}  width="80vw">
                    <Stack direction="row" spacing={5} sx={{justifyContent:  "center", alignContent: "center", display:  "flex"}}>
                        <Card component={Box} sx={{width: 550, height: 300, borderRadius:  2, background: `linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('${geschuetzt}')`, backgroundRepeat: "no-repeat", backgroundSize:  "cover", justifyContent: "center", alignContent: "center", display:"flex"}} elevation={6}>
                            
                            <CardContent sx={{justifyContent: "center", alignContent: "center", display: "flex", width: "auto", }}>
                                <Typography variant="h3" sx={{justifySelf: "center", alignSelf: "center", display: "flex", color: "#ffffff",  fontWeight: "bold",  textAlign:  "center"}}>Versicherungs-<br/>prämie?</Typography>
                            </CardContent>

                            
                        </Card>
                        
                            <Typography sx={{fontSize:  "1.2rem", alignSelf: "center", color: "#ffffff", width: "42%",  justifySelf:  "left",display: "flex", fontFamily: 'Plus Jakarta Sans, sans-serif'}}>
                                Die Höhe der Versicherungsprämie richtet sich in der Regel nach dem Wert des Hauses, der Lage und den individuellen Bedürfnissen des Hausbesitzers. Es ist wichtig, eine ausreichende Versicherungssumme zu wählen, um im Falle eines Schadens eine angemessene Entschädigung zu erhalten.                            </Typography>
                        
                    </Stack>
                    <Stack direction="row" spacing={5} sx={{justifyContent:  "center", alignContent: "center", display:  "flex"}}>
                        <Typography sx={{fontSize:  "1.2rem",  alignSelf: "center", color: "#ffffff", width: "42%", fontFamily: 'Plus Jakarta Sans, sans-serif'}}>
                            In einigen Fällen kann es vorkommen, dass eine Wohngebäudeversicherung für das Haus verpflichtend ist. Zum Beispiel kann dies der Fall sein, wenn das Haus noch finanziert wird und die Bank als Kreditgeber eine Versicherung als Bedingung für die Kreditvergabe verlangt.    </Typography>                     
                        <Card component={Box} sx={{width: 550,height: 300,  borderRadius: 2, background: `linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url('${verpflichtend}')`, backgroundRepeat: "no-repeat", backgroundSize:  "cover", justifyContent: "center", alignContent: "center", display:"flex"}} elevation={6}>
                            <CardContent sx={{justifyContent: "center", alignItems: "center", display: "flex", width:  "auto", }}>
                                <Typography variant="h3" sx={{justifySelf: "center", alignSelf: "center", display: "flex", color: "#ffffff",  fontWeight: "bold"}}>Verpflichtend?</Typography>
                            </CardContent>
                            
                        </Card>
                        
                    </Stack>

                     

                </Stack>

                
                   
            </div>
            
        </div>
        
        
        <div style={{ marginTop:  150, color: "#ffffff", justifyContent:  "center", alignContent: "center", display:  "flex", }}>
            <div style={{width: "80vw",}}>
            <Stack  direction="column" sx={{justifyItems:  "center",  alignItems: "center", display: "flex" }}>

                <Stack  direction="row" spacing="auto" sx={{justifyContent:  "center",  alignContent:  "center",  display: "flex", }}>
                    
                    <img alt="" loading="lazy" src={wissenswertes}  style={{ height: "auto", width: "auto"}}  />
                    <Typography  textAlign={"center"} color="#f7a400" sx={{alignSelf:  "center",  fontWeight:  "bold", fontSize: "4.5vw"}}>Wissenswertes</Typography>

                </Stack>
                
                <Stack sx={{marginTop: 10,  width: "80vw", justifySelf: "center", alignSelf: "center", display: "flex"}}  direction="row" spacing="auto">

                    <Card width={"25vw"} height={"auto"}  elevation={14} component={Box} sx={{borderRadius: 4}}>
                    <Stack direction="column" sx={{padding:  5}}>
                    <Card width="auto" height="auto" elevation={3}  component={Box} sx={{borderRadius: 5}}>
                        <CardMedia
                            component={"img"}
                            alt=""
                            loading="lazy"
                            image={ht}
                            width={"auto"}
                            height={"180vh"}
                        />

                    </Card>

                    <Stack  direction="column" height={"auto"} width={"auto"} sx={{justifyContent: "center", alignContent:  "center", display: "flex"}} mt={5}>
                        <Stack  direction={"column"}>
                        <Typography variant="h5" sx={{fontWeight:  "bold", fontSize:  "1.8rem"}} textAlign="center" mb={3}>Wichtige Themen</Typography>

                        <a href="/haeufigste_schaeden" title="Häufigste Schäden der Wohngebäudeversicherung" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Häufigste Schäden</span></Typography></a>
                        <a href="/glasbruch" title="Wohngebäudeversicherung: Glasbruch/Schäden" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3} mt={2} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Glasbruch</span></Typography></a>
                        <a href="/elementar" title="Wohngebäudeversicherung: Elementar" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3} mt={2} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Elementar</span></Typography></a>
                        <a href="/hochwasser" title="Wohngebäudeversicherung: Hochwasser" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3} mt={2} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Hochwasser</span></Typography></a>
                        
                        
                    </Stack>
                    </Stack>
                    </Stack>
                    </Card>

                    <Card width={"25vw"} height={"auto"}  elevation={14}  component={Box} sx={{borderRadius: 4}} >
                    <Stack direction="column" sx={{padding:  5}}>
                    <Card width="auto" height="auto" elevation={3}  component={Box} sx={{borderRadius: 5}}>
                        <CardMedia
                            component={"img"}
                            alt=""
                            loading="lazy"
                            image={currentT}
                            width={"auto"}
                            height={"180vh"}
                        />
                    </Card>
                    
                    <Stack  direction="column" height={"auto"} width={"auto"} sx={{justifyContent: "center", alignContent:  "center", display: "flex"}} mt={5}>
                        <Stack  direction={"column"}>
                        <Typography variant="h5" sx={{fontWeight:  "bold", fontSize:  "1.8rem"}} textAlign="center" mb={3}>Aktuelle Themen</Typography>
                        
                        <a href="/photovoltaik" title="Wohngebäudeversicherung: Photovoltaik" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Photovoltaik</span></Typography></a>
                        <a href="/vergleiche_im_internet" title="Wohngebäudeversicherung: Vergleiche" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3}  mt={2} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Vergleiche im Internet</span></Typography></a>
                        <a href="/testsieger" title="Wohngebäudeversicherung: Testsieger" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3} mt={2} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Testsieger</span></Typography></a>
                        <a href="/erfahrungen_wg" title="Wohngebäudeversicherung: Erfahrungen" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3} mt={2} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Erfahrungen  WG</span></Typography></a>
                        
                    </Stack>
                    </Stack>
                    </Stack>
                    </Card>

                    <Card width={"25vw"} height={"auto"}  elevation={14} component={Box} sx={{borderRadius: 4}} >
                    <Stack direction="column" sx={{padding:  5}}>
                    <Card width="auto" height="auto" elevation={3} component={Box} sx={{borderRadius: 5}}>
                        <CardMedia
                            component={"img"}
                            alt=""
                            loading="lazy"
                            image={useful}
                            width={"auto"}
                            height={"180vh"}
                        />
                    </Card>
                    
                    <Stack  direction="column" height={"auto"} width={"auto"} sx={{justifyContent: "center", alignContent:  "center", display: "flex"}} mt={5}>
                        <Stack  direction={"column"} >
                        <Typography variant="h5" sx={{fontWeight:  "bold", fontSize:  "1.8rem"}} textAlign="center" mb={3}>Nützliche Themen</Typography>

                        <a href="/neubau" title="Wohngebäudeversicherung: Neubau" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Neubau</span></Typography></a>
                        <a href="/wohnflaeche" title="Wohngebäudeversicherung: Wohnfläche" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3} mt={2} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Wohnfläche</span></Typography></a>
                        <a href="/vermieter" title="Wohngebäudeversicherung: Vermieter" style={{textDecoration: "none",  color: "#ffffff"}}><Typography textAlign="left" ml={5} mr={3} mt={2} color="#f7a400" fontSize={"1.4rem"}> &bull; <span style={{color: "#000000"}}>Vermieter</span></Typography></a>
                        
                        </Stack>
                       
                            
                    </Stack>
                    </Stack>
                    </Card>
                </Stack>
            </Stack>
        </div>
        </div>
        </div>

        
    );
}