import { Stack, Typography } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import logo  from  "../../../assets/Versicherungen.png";


export default  function FooterMob(){

    return(
        <footer>
        <div style={{ minHeight:  "180vh",  backgroundColor: "#3e4491", justifySelf:  "center",  alignContent:  "center", color: "#ffffff", }}>
            <Stack sx={{display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', paddingTop: 15, marginBottom:  12}} spacing={5}  direction="column">
                <Stack spacing={2} direction="column"  sx={{width:  "60vw"}}>
                <Stack direction="column " >
                    <EmailIcon  sx={{alignSelf: "center",   fontSize: "10vw",  color: "#f7a400"}}/>
                    <Typography  sx={{alignSelf: "center",  fontWeight: "bold", fontSize: "8vw"}}>E-Mail</Typography>
                    
                </Stack>
                    <Typography textAlign="center" sx={{fontSize:  "4.5vw"}}>versicherung@mr-money.de</Typography>

                </Stack>

                

                <Stack spacing={2} direction="column"  sx={{width:  "60vw"}}>
                <Stack spacing={2} direction="column ">
                    <CallIcon sx={{alignSelf: "center",   fontSize: "10vw", color: "#f7a400"}}/>
                    <Typography   sx={{alignSelf: "center",  fontWeight: "bold", fontSize: "8vw"}}>Telefon</Typography>
                    
                </Stack>
                <Typography textAlign="center"  sx={{fontSize:  "4.5vw"}}>03 72 96 / 92 73 96</Typography>
                </Stack>

                
                
                <Stack spacing={2} direction="column"  sx={{width:  "60vw"}}>
                <Stack spacing={3} direction="column ">
                    <SupportAgentIcon sx={{alignSelf: "center",  fontSize: "10vw",  color: "#f7a400"}}/>
                    <Typography sx={{alignSelf: "center",  fontWeight: "bold", fontSize:  "8vw"}}>Öffnungszeiten</Typography>
                    
                </Stack>
                <Typography  textAlign="center" sx={{fontSize:  "4.5vw"}}>Montag - Freitag <br/>08:30 - 17:30 Uhr</Typography>
                </Stack>
            </Stack>

            <Stack direction="column" spacing={3} sx={{ paddingBottom:  10,   }}>
                

                <Stack direction="column" spacing={5} sx={{alignSelf:  "center"}} justifyItems={"center"} alignItems={"center"} display={"flex"}>
                    <Stack direction="column"  justifyItems={"center"} alignItems={"center"} display={"flex"}>
                        <Typography sx={{fontSize:  "6vw", fontWeight: "bold", color:  "#f7a400", textAlign:  "center", justifySelf: "center", alignSelf: "center",  display:  "flex"}}>Service</Typography>
                        <a href="/agb"  title="Wohngebäudeversicherung: Allgemeine  Geschäftsbedingungen" style={{textDecoration: "none",  color: "#ffffff"}}><Typography  sx={{textAlign: "center",  fontSize: "5vw",  justifySelf: "center", alignSelf: "center",  display:  "flex",  paddingTop:  2}}>AGB</Typography></a>
                    </Stack>
                    <Stack direction="column" justifyItems={"center"} alignItems={"center"} display={"flex"}>
                        <Typography sx={{fontSize:  "6vw", fontWeight: "bold", color:  "#f7a400", textAlign:  "center", justifySelf: "center", alignSelf: "center",  display:  "flex"}}>Informationen</Typography>
                        <a href="/impressum" title="Wohngebäudeversicherung: Impressum" style={{textDecoration: "none",  color: "#ffffff"}}><Typography sx={{textAlign: "center",  fontSize: "5vw", justifySelf: "center", alignSelf: "center",  display:  "flex", paddingTop:  2}}>Impressum</Typography></a>
                        <a href="/datenschutz" title="Wohngebäudeversicherung: Datenschutz" style={{textDecoration: "none",  color: "#ffffff"}}><Typography sx={{textAlign: "center",  fontSize: "5vw", justifySelf: "center", alignSelf: "center",  display:  "flex", paddingTop:  2}}>Datenschutz</Typography></a>
                    </Stack>
                    <Stack direction="column" justifyItems={"center"} alignItems={"center"} display={"flex"}>
                        <Typography sx={{fontSize:  "6vw", fontWeight: "bold", color:  "#f7a400", textAlign:  "center", justifySelf: "center", alignSelf: "center",  display:  "flex"}}>Kontakt</Typography>
                        <a href="/kontakt" title="Wohngebäudeversicherung: Kontaktformular" style={{textDecoration: "none",  color: "#ffffff"}}><Typography sx={{textAlign: "center",  fontSize: "5vw", justifySelf: "center", alignSelf: "center",  display:  "flex", paddingTop:  2}}>Kontakformular</Typography></a>
                    </Stack>
                </Stack>

                <Stack direction="row" pt={8} sx={{justifyContent: "center", alignContent: "center", display:  "flex"}}>
                    <img alt="Wohngebäude Versicherungen" loading="lazy" src={logo} width={150} />
                    <Typography  sx={{  alignSelf: "end",  paddingBottom: 1,  fontWeight:  "bold", fontSize: "5vw"}}>Wohngebäude<br/>Versicherungen</Typography>
                </Stack>
                
            </Stack>

            <div  style={{backgroundColor: "#292a73",color: "lightgray",  justifyContent: "center",  alignContent: "center", display: "flex" }}>
                <Stack  direction={"column"} p={2}>
                    <Typography textAlign="center" sx={{justifySelf: "center", alignSelf: "center",  display:  "flex", fontSize: "4vw", fontWeight:  "bold"}} >Realisiert mit React</Typography>
                </Stack>
            </div>
        </div>
        </footer>
    );
}