import { BrowserRouter, Route, Routes } from 'react-router-dom';



import Home from "./pages/Home";
import RechnerSite from './pages/RechnerSite';
import HSSite from './pages/HSSite';
import GBSite from './pages/GB';
import HWSite from './pages/HWSite';
import PHOSite from './pages/PHOSite';
import ElementarsSite from './pages/ElementarSite';
import EWGSite from './pages/EWGSite';
import NBSite from './pages/NBSite';
import TSSite from './pages/TSSite';
import VMSite from './pages/VMSite';
import WFSite from './pages/WFSite';
import VIISite from './pages/VIISite';
import KontaktForm from './pages/KontaktSite';
import ImpSite from './pages/ImpSite';
import AGBSite from './pages/AGBSite';
import DSSite from './pages/DSSite';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        
          <Route index element={<Home />} />
          <Route path="vergleichsrechner" element={<RechnerSite/>} />
          
          <Route path="haeufigste_schaeden" element={<HSSite />} />
          
          <Route path="glasbruch" element={<GBSite />} />

          <Route path="hochwasser" element={<HWSite />} />

          <Route path="photovoltaik" element={<PHOSite />} />

          <Route path="vergleiche_im_internet" element={<VIISite />} />

          <Route path="elementar" element={<ElementarsSite />} />

          <Route path="erfahrungen_wg" element={<EWGSite />} />

          <Route path="neubau" element={<NBSite />} />

          <Route path="testsieger" element={<TSSite/>} />

          <Route path="vermieter" element={<VMSite />} />

          <Route path="wohnflaeche" element={<WFSite />} />

          <Route path="kontakt" element={<KontaktForm />} />

          <Route path="impressum" element={<ImpSite/>} />

          <Route path="agb" element={<AGBSite/>} />

          <Route path="datenschutz" element={<DSSite/>} />
          
      </Routes>
    </BrowserRouter>
  );
}

export default App;
