import { Box, Button, Stack, Typography } from "@mui/material";
import { Parallax } from "react-parallax";
import para_img from "../../../assets/WEBP/para_img_handy.webp";


export default function ToRechnerMob(){

    return(
                <Parallax blur={5} bgImage={para_img} alt="" loading="lazy" height="auto" style={{marginTop:  50}} strength={200}>
                            <div style={{height: "auto",   justifyContent: "center", alignContent: "center",   display:  "flex",  paddingTop: 40, paddingBottom: 40}}>
                                <Stack direction="column" spacing={5} sx={{justifyItems:  "center",  alignItems: "center", display:  "flex"}}>
                                    <Typography  width="80vw"  sx={{fontSize: "8vw", textAlign:  "center",  alignSelf: "center", fontWeight: "bold", color: "#f7a400"}}>Mit unserem Vergleichsrechner <br/>finden Sie das beste Angebot</Typography>
                                    
                                    <a href="/vergleichsrechner" underline="none" color="#ffffff">
                                        <Button variant="contained"  component={Box} sx={{width: "80vw",  height: 60,  borderRadius: 0, fontWeight:  "bold",  fontSize: "4vw",  }}>Zum Vergleichsrechner</Button>
                                    </a>
                                </Stack>
                            </div>
                        </Parallax>
    );
}