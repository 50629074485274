
import { CssBaseline, Stack, Typography, useMediaQuery } from "@mui/material";
import startImage from "../assets/WEBP/faq_image5.webp";
import startImageHandy from "../assets/WEBP/faq_image5_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import ToRechner from "../components/RLE/RLE";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import { Helmet } from 'react-helmet';


export default function  HWSite(){

    const isBig  = useMediaQuery('(min-width:600px)');
    
    if(isBig){

    return(
        <div id="header">
        <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Hochwasser</title>
                <meta name="description" content="Wie ist Hochwasser in der Wohngebäudeversicherung abgedeckt?"/>
                <meta name="keywords" content="Wohngebäudeversicherung Glas, Wohngebäudeversicherung Elementar, Wohngebäudeversicherung Schäden, Wohngebäudeversicherung Wasser, Wohngebäudeversicherung Hochwasser" />

            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>

                <Typography variant="h1" sx={{justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Hochwasser</Typography>

            </div>
            <div style={{marginTop: 70,  marginBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                <div style={{width: "60vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                    <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "1.2rem",}}>Hochwasser ist eine der schwersten Naturkatastrophen, die erhebliche Schäden an Gebäuden und Immobilien verursachen kann. Die meisten Versicherungen bieten Hochwasserschutz als Teil ihrer Wohngebäudeversicherung an. In diesem Text werden wir uns eingehender mit dem Thema Hochwasser in der Wohngebäudeversicherung befassen.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Es ist ein Risiko, das für viele Hausbesitzer in Deutschland eine reale Bedrohung darstellt. Insbesondere in Gebieten, die in der Nähe von Flüssen, Bächen oder Küsten liegen, kann es zu schweren Schäden kommen. In diesem Zusammenhang stellt sich die Frage, ob Hochwasserschäden durch eine Wohngebäudeversicherung abgedeckt werden und welche Punkte es hierbei zu beachten gibt</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Die Wohngebäudeversicherung deckt in der Regel Schäden durch Leitungswasser und Elementarschäden ab. Ein Elementarschaden tritt auf, wenn ein Ereignis, das auf natürliche Weise entsteht, einen Schaden verursacht, wie z.B. Hochwasser. Es ist wichtig zu beachten, dass Hochwasserschutz nicht automatisch in der Wohngebäudeversicherung enthalten ist. Wenn Sie also in einem Hochwassergebiet leben, sollten Sie sicherstellen, dass Ihr Versicherer dies abdeckt.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Bei der Wohngebäudeversicherung gibt es in der Regel drei Arten von Hochwasserschäden, die abgedeckt werden: Schäden durch Überschwemmung von Gewässern, Schäden durch Rückstau und Schäden durch Witterungsniederschläge wie Starkregen oder Schneeschmelze. Eine wichtige Rolle spielt hierbei die genaue Definition der Schadensursache im Versicherungsvertrag.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Im Falle eines Hochwasserschadens sollten Sie so schnell wie möglich Ihre Versicherungsgesellschaft kontaktieren. Es ist wichtig, dass Sie alle Schäden dokumentieren und Fotos von den beschädigten Gegenständen oder Räumen machen. Eine schnelle Reaktion kann dazu beitragen, dass der Schaden begrenzt wird und dass Ihre Versicherungsgesellschaft den Schaden schnell und effektiv bearbeitet.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Beachten Sie, dass Hochwasserschäden möglicherweise nicht vollständig durch die Wohngebäudeversicherung abgedeckt sind. Die Versicherung kann Begrenzungen und Ausschlüsse enthalten, die sich auf das Ausmaß der Abdeckung auswirken können. Lesen Sie daher die Vertragsbedingungen Ihrer Wohngebäudeversicherung sorgfältig durch, um sicherzustellen, dass Sie verstehen, was abgedeckt ist und was nicht.</Typography>
                    
                        <Typography sx={{fontSize:  "1.2rem",}}>Um das Risiko von Hochwasserschäden zu reduzieren, sollten Sie regelmäßig Wartungsarbeiten an Ihrem Haus durchführen. Stellen Sie sicher, dass Ihre Entwässerungssysteme ordnungsgemäß funktionieren und dass Sie keine Gegenstände oder Pflanzen in der Nähe von Entwässerungsgräben oder -kanälen aufbewahren, die den Wasserfluss blockieren könnten. Achten Sie auch darauf, dass Sie keine Gegenstände in den Keller stellen, die durch Wasser beschädigt werden können, und dass Sie den Kellerbereich regelmäßig überprüfen.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Bei der Wahl der richtigen Wohngebäudeversicherung ist es daher wichtig, genau zu prüfen, welche Schäden durch die Versicherung abgedeckt werden. Hierbei sollte man nicht nur auf den Preis achten, sondern auch auf die Leistungen und Bedingungen des Versicherers.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Eine weitere wichtige Frage im Zusammenhang mit Hochwasserschäden ist die Höhe der Versicherungssumme. Hierbei sollte man darauf achten, dass die Versicherungssumme ausreichend hoch ist, um im Falle eines Schadens alle Kosten abdecken zu können. Eine Unterversicherung kann im Schadensfall zu erheblichen finanziellen Problemen führen.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Insgesamt ist Hochwasserschutz ein wichtiger Bestandteil der Wohngebäudeversicherung. Wenn Sie in einem Hochwassergebiet leben, sollten Sie sicherstellen, dass Ihre Versicherungsgesellschaft einen Hochwasserschutz anbietet.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Zusammenfassend lässt sich sagen, dass Hochwasserschäden durch eine Wohngebäudeversicherung abgedeckt werden können, jedoch sollten hierbei die genauen Bedingungen und Leistungen des Versicherers geprüft werden. Eine ausreichend hohe Versicherungssumme sowie Maßnahmen zur Reduzierung des Hochwasserrisikos sind weitere wichtige Faktoren, die bei der Absicherung von Hochwasserschäden berücksichtigt werden sollten.</Typography>
                    
                    </Stack>
                
                </div>
            </div>

            <ToRechner/>

        </div>
        <Footer/>
        <STT/>
        </div>
    );

    }

    else if(!isBig){

        return(
            <div id="header">
            <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Hochwasser</title>
                    <meta name="description" content="Wie ist Hochwasser in der Wohngebäudeversicherung abgedeckt?"/>
                    <meta name="keywords" content="Wohngebäudeversicherung Glas, Wohngebäudeversicherung Elementar, Wohngebäudeversicherung Schäden, Wohngebäudeversicherung Wasser, Wohngebäudeversicherung Hochwasser" />
                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>
    
                    <Typography sx={{fontSize:  "15vw",justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Hochwasser</Typography>
    
                </div>
                <div style={{marginTop: 70,  marginBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                    <div style={{width: "80vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                        <Stack direction="column" spacing={5}>
                            <Typography sx={{fontSize:  "5vw",}}>Hochwasser ist eine der schwersten Naturkatastrophen, die erhebliche Schäden an Gebäuden und Immobilien verursachen kann. Die meisten Versicherungen bieten Hochwasserschutz als Teil ihrer Wohngebäudeversicherung an. In diesem Text werden wir uns eingehender mit dem Thema Hochwasser in der Wohngebäudeversicherung befassen.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Es ist ein Risiko, das für viele Hausbesitzer in Deutschland eine reale Bedrohung darstellt. Insbesondere in Gebieten, die in der Nähe von Flüssen, Bächen oder Küsten liegen, kann es zu schweren Schäden kommen. In diesem Zusammenhang stellt sich die Frage, ob Hochwasserschäden durch eine Wohngebäudeversicherung abgedeckt werden und welche Punkte es hierbei zu beachten gibt</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Die Wohngebäudeversicherung deckt in der Regel Schäden durch Leitungswasser und Elementarschäden ab. Ein Elementarschaden tritt auf, wenn ein Ereignis, das auf natürliche Weise entsteht, einen Schaden verursacht, wie z.B. Hochwasser. Es ist wichtig zu beachten, dass Hochwasserschutz nicht automatisch in der Wohngebäudeversicherung enthalten ist. Wenn Sie also in einem Hochwassergebiet leben, sollten Sie sicherstellen, dass Ihr Versicherer dies abdeckt.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Bei der Wohngebäudeversicherung gibt es in der Regel drei Arten von Hochwasserschäden, die abgedeckt werden: Schäden durch Überschwemmung von Gewässern, Schäden durch Rückstau und Schäden durch Witterungsniederschläge wie Starkregen oder Schneeschmelze. Eine wichtige Rolle spielt hierbei die genaue Definition der Schadensursache im Versicherungsvertrag.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Im Falle eines Hochwasserschadens sollten Sie so schnell wie möglich Ihre Versicherungsgesellschaft kontaktieren. Es ist wichtig, dass Sie alle Schäden dokumentieren und Fotos von den beschädigten Gegenständen oder Räumen machen. Eine schnelle Reaktion kann dazu beitragen, dass der Schaden begrenzt wird und dass Ihre Versicherungsgesellschaft den Schaden schnell und effektiv bearbeitet.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Beachten Sie, dass Hochwasserschäden möglicherweise nicht vollständig durch die Wohngebäudeversicherung abgedeckt sind. Die Versicherung kann Begrenzungen und Ausschlüsse enthalten, die sich auf das Ausmaß der Abdeckung auswirken können. Lesen Sie daher die Vertragsbedingungen Ihrer Wohngebäudeversicherung sorgfältig durch, um sicherzustellen, dass Sie verstehen, was abgedeckt ist und was nicht.</Typography>
                        
                            <Typography sx={{fontSize:  "5vw",}}>Um das Risiko von Hochwasserschäden zu reduzieren, sollten Sie regelmäßig Wartungsarbeiten an Ihrem Haus durchführen. Stellen Sie sicher, dass Ihre Entwässerungssysteme ordnungsgemäß funktionieren und dass Sie keine Gegenstände oder Pflanzen in der Nähe von Entwässerungsgräben oder -kanälen aufbewahren, die den Wasserfluss blockieren könnten. Achten Sie auch darauf, dass Sie keine Gegenstände in den Keller stellen, die durch Wasser beschädigt werden können, und dass Sie den Kellerbereich regelmäßig überprüfen.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Bei der Wahl der richtigen Wohngebäudeversicherung ist es daher wichtig, genau zu prüfen, welche Schäden durch die Versicherung abgedeckt werden. Hierbei sollte man nicht nur auf den Preis achten, sondern auch auf die Leistungen und Bedingungen des Versicherers.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Eine weitere wichtige Frage im Zusammenhang mit Hochwasserschäden ist die Höhe der Versicherungssumme. Hierbei sollte man darauf achten, dass die Versicherungssumme ausreichend hoch ist, um im Falle eines Schadens alle Kosten abdecken zu können. Eine Unterversicherung kann im Schadensfall zu erheblichen finanziellen Problemen führen.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Insgesamt ist Hochwasserschutz ein wichtiger Bestandteil der Wohngebäudeversicherung. Wenn Sie in einem Hochwassergebiet leben, sollten Sie sicherstellen, dass Ihre Versicherungsgesellschaft einen Hochwasserschutz anbietet.</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Zusammenfassend lässt sich sagen, dass Hochwasserschäden durch eine Wohngebäudeversicherung abgedeckt werden können, jedoch sollten hierbei die genauen Bedingungen und Leistungen des Versicherers geprüft werden. Eine ausreichend hohe Versicherungssumme sowie Maßnahmen zur Reduzierung des Hochwasserrisikos sind weitere wichtige Faktoren, die bei der Absicherung von Hochwasserschäden berücksichtigt werden sollten.</Typography>
                        
                        </Stack>
                    
                    </div>
                </div>
    
                <ToRechnerMob/>
    
            </div>
            <FooterMob/>
            <STTMob/>
            </div>
        );


    }
}