
import { CssBaseline, List, ListItem, Stack, Typography, useMediaQuery } from "@mui/material";
import startImage from "../assets/WEBP/faq_image5.webp";
import startImageHandy from "../assets/WEBP/faq_image5_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import ToRechner from "../components/RLE/RLE";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import { Helmet } from 'react-helmet';


export default function  VIISite(){

    const isBig  = useMediaQuery('(min-width:600px)');
    
    if(isBig){

    return(
        <div id="header">
        <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Vergleiche</title>
                <meta name="description" content="Vergleichen Sie hier die besten Wohngebäudeversicherungen. "/>
                <meta name="keywords" content="Wohngebäudeversicherung Vergleiche, Wohngebäudeversicherung Versicherungsvergleiche, Vergleich, Versicherung" />


            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>

                <Typography variant="h1" sx={{justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Vergleiche im  Internet</Typography>

            </div>
            <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                
                <div style={{width: "60vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                    <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "1.2rem",}}>Wohngebäudeversicherungen sind ein wichtiger Schutz für Hausbesitzer, um ihr Eigentum vor unvorhergesehenen Schäden zu schützen. Da es jedoch eine Vielzahl von Versicherungsanbietern gibt, kann es schwierig sein, die richtige Versicherung zu finden. Hier kommen Vergleichsportale ins Spiel, die eine schnelle und unkomplizierte Möglichkeit bieten, Wohngebäudeversicherungen zu vergleichen.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Im Internet gibt es zahlreiche Vergleichsportale, die es Hausbesitzern ermöglichen, eine geeignete Wohngebäudeversicherung zu finden. Dabei können Interessierte in der Regel verschiedene Anbieter und Tarife miteinander vergleichen und sich so einen Überblick über die unterschiedlichen Leistungen und Preise verschaffen. Eine Vielzahl von Kriterien kann bei der Suche und dem Vergleich von Versicherungen berücksichtigt werden, wie zum Beispiel die Deckungssumme, die Selbstbeteiligung oder die möglichen Zusatzleistungen.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Vergleichsportale bieten auch den Vorteil, dass Hausbesitzer schnell und unkompliziert Versicherungsangebote erhalten können. In der Regel kann der Versicherungsabschluss online erfolgen, was Zeit und Mühe spart.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Allerdings sollten Hausbesitzer bei der Nutzung von Vergleichsportalen darauf achten, dass diese unabhängig und seriös sind. Nicht alle Vergleichsportale sind unabhängig und manche haben Verträge mit Versicherungsanbietern, die die Ergebnisse beeinflussen können. Daher ist es wichtig, mehrere Portale zu nutzen und die Angebote sorgfältig zu vergleichen, um die richtige Wahl zu treffen.</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Zusammenfassend kann gesagt werden, dass Vergleichsportale eine gute Möglichkeit bieten, Wohngebäudeversicherungen schnell und unkompliziert zu vergleichen. Es ist jedoch wichtig, darauf zu achten, dass die Vergleichsportale unabhängig und seriös sind, um eine fundierte Entscheidung zu treffen. Eine gründliche Recherche und der Vergleich mehrerer Angebote können dabei helfen, die passende Wohngebäudeversicherung zu finden.</Typography>

                    </Stack>
                    
                </div>
                
                
                
                
                
            </div>

            <ToRechner/>

        </div>
        <Footer/>
        <STT/>
        </div>
    );

    }

    else  if(!isBig){

        return(
            <div id="header">
            <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Vergleiche</title>
                    <meta name="description" content="Vergleichen Sie hier die besten Wohngebäudeversicherungen. "/>
                    <meta name="keywords" content="Wohngebäudeversicherung Vergleiche, Wohngebäudeversicherung Versicherungsvergleiche, Vergleich, Versicherung" />

                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>
    
                    <Typography sx={{fontSize:  "13vw",justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff",  textAlign: "center"}}>Vergleiche im <br/> Internet</Typography>
    
                </div>
                <div style={{marginTop: 70,  paddingBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                    
                    <div style={{width: "80vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                        <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "5vw",}}>Wohngebäudeversicherungen sind ein wichtiger Schutz für Hausbesitzer, um ihr Eigentum vor unvorhergesehenen Schäden zu schützen. Da es jedoch eine Vielzahl von Versicherungsanbietern gibt, kann es schwierig sein, die richtige Versicherung zu finden. Hier kommen Vergleichsportale ins Spiel, die eine schnelle und unkomplizierte Möglichkeit bieten, Wohngebäudeversicherungen zu vergleichen.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Im Internet gibt es zahlreiche Vergleichsportale, die es Hausbesitzern ermöglichen, eine geeignete Wohngebäudeversicherung zu finden. Dabei können Interessierte in der Regel verschiedene Anbieter und Tarife miteinander vergleichen und sich so einen Überblick über die unterschiedlichen Leistungen und Preise verschaffen. Eine Vielzahl von Kriterien kann bei der Suche und dem Vergleich von Versicherungen berücksichtigt werden, wie zum Beispiel die Deckungssumme, die Selbstbeteiligung oder die möglichen Zusatzleistungen.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Vergleichsportale bieten auch den Vorteil, dass Hausbesitzer schnell und unkompliziert Versicherungsangebote erhalten können. In der Regel kann der Versicherungsabschluss online erfolgen, was Zeit und Mühe spart.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Allerdings sollten Hausbesitzer bei der Nutzung von Vergleichsportalen darauf achten, dass diese unabhängig und seriös sind. Nicht alle Vergleichsportale sind unabhängig und manche haben Verträge mit Versicherungsanbietern, die die Ergebnisse beeinflussen können. Daher ist es wichtig, mehrere Portale zu nutzen und die Angebote sorgfältig zu vergleichen, um die richtige Wahl zu treffen.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Zusammenfassend kann gesagt werden, dass Vergleichsportale eine gute Möglichkeit bieten, Wohngebäudeversicherungen schnell und unkompliziert zu vergleichen. Es ist jedoch wichtig, darauf zu achten, dass die Vergleichsportale unabhängig und seriös sind, um eine fundierte Entscheidung zu treffen. Eine gründliche Recherche und der Vergleich mehrerer Angebote können dabei helfen, die passende Wohngebäudeversicherung zu finden.</Typography>
    
                        </Stack>
                        
                    </div>
                    
                    
                </div>
    
                <ToRechnerMob/>
    
            </div>
            <FooterMob/>
            <STTMob/>
            </div>
        );

    }
}