
import { CssBaseline, List, ListItem, Stack, Typography, useMediaQuery } from "@mui/material";
import startImage from "../assets/WEBP/faq_image5.webp";
import startImageHandy from "../assets/WEBP/faq_image5_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import ToRechner from "../components/RLE/RLE";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import { Helmet } from 'react-helmet';


export default function  TSSite(){

    const isBig  = useMediaQuery('(min-width:600px)');
    
    if(isBig){

    return(
        <div id="header">   
        <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
            <Helmet>
                <title>Wohngebäudeversicherung: Testsieger</title>
                <meta name="description" content="Welche Versicherer schneiden in Tests am besten ab"/>
                <meta name="keywords" content="Wohngebäudeversicherung Testsieger, Wohngebäudeversicherung Sieger, Wohngebäudeversicherung Test, Versicherungsvergleich" />

            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>

                <Typography variant="h1" sx={{justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Testsieger WG-Versicherung</Typography>

            </div>
            <div style={{marginTop: 70,  marginBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                
            <div style={{width: "60vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                    <Stack direction="column" spacing={5}>
                    <Typography sx={{fontSize:  "1.2rem",}}>In der heutigen Zeit ist es wichtiger denn je, sein Eigenheim gegen Schäden abzusichern. Dazu gehört auch der Abschluss einer Wohngebäudeversicherung. Diese Versicherung sichert das eigene Haus oder die eigene Wohnung gegen Schäden ab, die durch Feuer, Sturm, Hagel oder Leitungswasser entstehen können. Um als Verbraucher die richtige Wohngebäudeversicherung auszuwählen, kann es hilfreich sein, sich an Testsiegern zu orientieren.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Die Stiftung Warentest ist eine der bekanntesten Organisationen, die regelmäßig Tests von Wohngebäudeversicherungen durchführt. Im letzten Test im Jahr 2021 wurden insgesamt 164 Tarife von 67 Versicherern getestet. Dabei wurden verschiedene Kriterien wie Deckungsumfang, Schadensregulierung und Preis bewertet. Insgesamt erhielten 31 Tarife die Note "sehr gut".</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Zu den Testsiegern in der Wohngebäudeversicherung gehören beispielsweise die Alte Leipziger, die Interrisk und die VHV. Diese Versicherer überzeugten durch einen umfangreichen Deckungsumfang, eine schnelle und zuverlässige Schadensregulierung sowie ein gutes Preis-Leistungs-Verhältnis.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Die Interrisk punktet mit einem starken Schutz bei Feuer- und Leitungswasserschäden sowie einem attraktiven Preis-Leistungs-Verhältnis. Die VHV bietet unter anderem eine Garantie gegen unterversicherte Gebäude sowie einen umfangreichen Schutz bei Schäden durch Sturm und Hagel. Auch die Alte Leipziger konnte in Tests überzeugen. Vor allem die Leistungen im Schadensfall wurden positiv bewertet.</Typography>

                    <Typography sx={{fontSize:  "1.2rem",}}>Es ist jedoch wichtig zu beachten, dass der Testsieger nicht zwangsläufig die beste Wahl für jeden Verbraucher ist. Denn jeder Verbraucher hat individuelle Bedürfnisse und Anforderungen. Aus diesem Grund sollten sich Verbraucher nicht ausschließlich auf Testsieger verlassen, sondern sich auch selbstständig über verschiedene Angebote informieren und diese miteinander vergleichen.</Typography>

                    <Stack  direction="column" spacing={3}>

                        <Typography sx={{fontSize:  "1.2rem",}}>Worauf sollten Sie bei der Wahl einer Wohngebäudeversicherung achten?</Typography>

                        <Typography sx={{fontSize:  "1.2rem",}}>Testsieger sind ein wichtiger Anhaltspunkt bei der Wahl einer Wohngebäudeversicherung, sollten aber nicht das einzige Kriterium sein. Denn jeder Hausbesitzer hat individuelle Bedürfnisse und Anforderungen an seine Versicherung. Folgende Punkte sollten Sie bei der Wahl einer Wohngebäudeversicherung beachten:</Typography>

                        <List>
                            <ListItem>
                                <Typography sx={{fontSize:  "1.2rem",}}><span style={{ fontWeight:  "bold",  color: "#f7a400"}}>&bull;</span> <span style={{fontWeight: "bold"}}>Leistungsumfang:</span> Überprüfen Sie, welche Schäden in der Versicherung abgedeckt sind. Elementarschäden wie Überschwemmung oder Erdbeben sind oft nicht automatisch inbegriffen und müssen gesondert vereinbart werden.</Typography>
                            </ListItem>

                            <ListItem>
                                <Typography sx={{fontSize:  "1.2rem",}}><span style={{ fontWeight:  "bold", color: "#f7a400"}}>&bull;</span> <span style={{fontWeight: "bold"}}>Versicherungssumme:</span> Stellen Sie sicher, dass die Versicherungssumme ausreichend ist, um alle Kosten im Schadensfall zu decken.</Typography>
                            </ListItem>

                            <ListItem>
                                <Typography sx={{fontSize:  "1.2rem",}}><span style={{ fontWeight:  "bold", color: "#f7a400"}}>&bull;</span> <span style={{fontWeight: "bold"}}>Selbstbeteiligung:</span> Achten Sie auf die Höhe der Selbstbeteiligung. Je höher diese ist, desto niedriger ist in der Regel auch der Versicherungsbeitrag. Bedenken Sie jedoch, dass im Schadensfall auch eine hohe Selbstbeteiligung anfallen kann.</Typography>
                            </ListItem>

                            <ListItem>
                                <Typography sx={{fontSize:  "1.2rem",}}><span style={{ fontWeight:  "bold",  color: "#f7a400"}}>&bull;</span> <span style={{fontWeight: "bold"}}>Versicherungsbedingungen:</span> Lesen Sie die Versicherungsbedingungen genau durch und klären Sie offene Fragen mit dem Versicherer. Wichtig ist auch, dass die Versicherung an die individuellen Bedürfnisse angepasst werden kann.</Typography>
                            </ListItem>

                            <ListItem>
                                <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontWeight:  "bold", color: "#f7a400"}}>&bull;</span> <span style={{fontWeight: "bold"}}>Kundenservice:</span> Achten Sie auf einen guten Kundenservice, der im Schadensfall schnell und unkompliziert weiterhilft.</Typography>
                            </ListItem>
                    </List>
                        
                    </Stack>

                    
                    </Stack>
                
                </div>
                
                
                
                
                
            </div>

            <ToRechner/>

        </div>
        <Footer/>
        <STT/>
        </div>
    );

    }

    else  if(!isBig){

        return(
            <div id="header">   
            <div style={{minHeight: "130vh",  backgroundColor: "#1a1b4b"}}>
                <Helmet>
                    <title>Wohngebäudeversicherung: Testsieger</title>
                    <meta name="description" content="Welche Versicherer schneiden in Tests am besten ab"/>
                    <meta name="keywords" content="Wohngebäudeversicherung Testsieger, Wohngebäudeversicherung Sieger, Wohngebäudeversicherung Test, Versicherungsvergleich" />


                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>
    
                    <Typography  sx={{fontSize:  "15vw",justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff",  textAlign: "center"}}>Testsieger </Typography>
    
                </div>
                <div style={{marginTop: 70,  marginBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                    



                    <div style={{width: "80vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                        <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "5vw",}}>In der heutigen Zeit ist es wichtiger denn je, sein Eigenheim gegen Schäden abzusichern. Dazu gehört auch der Abschluss einer Wohngebäudeversicherung. Diese Versicherung sichert das eigene Haus oder die eigene Wohnung gegen Schäden ab, die durch Feuer, Sturm, Hagel oder Leitungswasser entstehen können. Um als Verbraucher die richtige Wohngebäudeversicherung auszuwählen, kann es hilfreich sein, sich an Testsiegern zu orientieren.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Die Stiftung Warentest ist eine der bekanntesten Organisationen, die regelmäßig Tests von Wohngebäudeversicherungen durchführt. Im letzten Test im Jahr 2021 wurden insgesamt 164 Tarife von 67 Versicherern getestet. Dabei wurden verschiedene Kriterien wie Deckungsumfang, Schadensregulierung und Preis bewertet. Insgesamt erhielten 31 Tarife die Note "sehr gut".</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Zu den Testsiegern in der Wohngebäudeversicherung gehören beispielsweise die Alte Leipziger, die Interrisk und die VHV. Diese Versicherer überzeugten durch einen umfangreichen Deckungsumfang, eine schnelle und zuverlässige Schadensregulierung sowie ein gutes Preis-Leistungs-Verhältnis.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Die Interrisk punktet mit einem starken Schutz bei Feuer- und Leitungswasserschäden sowie einem attraktiven Preis-Leistungs-Verhältnis. Die VHV bietet unter anderem eine Garantie gegen unterversicherte Gebäude sowie einen umfangreichen Schutz bei Schäden durch Sturm und Hagel. Auch die Alte Leipziger konnte in Tests überzeugen. Vor allem die Leistungen im Schadensfall wurden positiv bewertet.</Typography>
    
                        <Typography sx={{fontSize:  "5vw",}}>Es ist jedoch wichtig zu beachten, dass der Testsieger nicht zwangsläufig die beste Wahl für jeden Verbraucher ist. Denn jeder Verbraucher hat individuelle Bedürfnisse und Anforderungen. Aus diesem Grund sollten sich Verbraucher nicht ausschließlich auf Testsieger verlassen, sondern sich auch selbstständig über verschiedene Angebote informieren und diese miteinander vergleichen.</Typography>
    
                        <Stack  direction="column" spacing={3}>
    
                            <Typography sx={{fontSize:  "5vw",}}>Worauf sollten Sie bei der Wahl einer Wohngebäudeversicherung achten?</Typography>
    
                            <Typography sx={{fontSize:  "5vw",}}>Testsieger sind ein wichtiger Anhaltspunkt bei der Wahl einer Wohngebäudeversicherung, sollten aber nicht das einzige Kriterium sein. Denn jeder Hausbesitzer hat individuelle Bedürfnisse und Anforderungen an seine Versicherung. Folgende Punkte sollten Sie bei der Wahl einer Wohngebäudeversicherung beachten:</Typography>
    
                            <List>
                                <ListItem>
                                    <Typography sx={{fontSize:  "5vw",}}><span style={{ fontWeight:  "bold",  color: "#f7a400"}}>&bull;</span> <span style={{fontWeight: "bold"}}>Leistungsumfang:</span> Überprüfen Sie, welche Schäden in der Versicherung abgedeckt sind. Elementarschäden wie Überschwemmung oder Erdbeben sind oft nicht automatisch inbegriffen und müssen gesondert vereinbart werden.</Typography>
                                </ListItem>
    
                                <ListItem>
                                    <Typography sx={{fontSize:  "5vw",}}><span style={{ fontWeight:  "bold", color: "#f7a400"}}>&bull;</span> <span style={{fontWeight: "bold"}}>Versicherungssumme:</span> Stellen Sie sicher, dass die Versicherungssumme ausreichend ist, um alle Kosten im Schadensfall zu decken.</Typography>
                                </ListItem>
    
                                <ListItem>
                                    <Typography sx={{fontSize:  "5vw",}}><span style={{ fontWeight:  "bold", color: "#f7a400"}}>&bull;</span> <span style={{fontWeight: "bold"}}>Selbstbeteiligung:</span> Achten Sie auf die Höhe der Selbstbeteiligung. Je höher diese ist, desto niedriger ist in der Regel auch der Versicherungsbeitrag. Bedenken Sie jedoch, dass im Schadensfall auch eine hohe Selbstbeteiligung anfallen kann.</Typography>
                                </ListItem>
    
                                <ListItem>
                                    <Typography sx={{fontSize:  "5vw",}}><span style={{ fontWeight:  "bold",  color: "#f7a400"}}>&bull;</span> <span style={{fontWeight: "bold"}}>Versicherungsbedingungen:</span> Lesen Sie die Versicherungsbedingungen genau durch und klären Sie offene Fragen mit dem Versicherer. Wichtig ist auch, dass die Versicherung an die individuellen Bedürfnisse angepasst werden kann.</Typography>
                                </ListItem>
    
                                <ListItem>
                                    <Typography sx={{fontSize:  "5vw",}}><span style={{fontWeight:  "bold", color: "#f7a400"}}>&bull;</span> <span style={{fontWeight: "bold"}}>Kundenservice:</span> Achten Sie auf einen guten Kundenservice, der im Schadensfall schnell und unkompliziert weiterhilft.</Typography>
                                </ListItem>
                        </List>
                            
                        </Stack>
    
                        
                        </Stack>
                    
                    </div>
                </div>
    
                <ToRechnerMob/>
    
            </div>
            <FooterMob/>
            <STTMob/>
            </div>
        );

    }
}