import { Container, CssBaseline, Stack } from "@mui/material";
import Al_Logo  from "../../assets/Allianz.png";
import In_Logo  from "../../assets/Interrisk.png";
import VHV_Logo  from "../../assets/VHV.png";
import AR_Logo  from "../../assets/adam-riese.png";

import React from "react";


export default function VertrauenDiv(){

    return(
        <div style={{backgroundColor:  "#f7a400", height: "15vh", justifyContent: "center",  alignContent: "center", display: "flex", }}>
            <CssBaseline/>
            <Container sx={{  display: "flex",
                    flexFlow: "row" ,
                    justifyContent: "center",
                    alignItems: "center"}}>
                <div style={{display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', }}>        
                    <Stack direction="row" spacing={"auto"} sx={{justifyContent: "center",  alignContent: "center", display: "flex", width: "100vw",  paddingLeft: "12%", paddingRight: "12%"}}>
                        
                        
                        <img alt="Allianz" loading="lazy" src={Al_Logo} width={"auto"} height={60} style={{justifySelf: "center", alignSelf: "center", display: "flex"}} />
                        <img alt="InterRisk" loading="lazy" src={In_Logo} width={"auto"} height={60} style={{justifySelf: "center", alignSelf: "center", display: "flex"}}/>
                        <img alt="VHV Versicherungen" loading="lazy" src={VHV_Logo} width={"auto"} height={60} style={{justifySelf: "center", alignSelf: "center", display: "flex"}}/>
                        <img alt="Adam Riese" loading="lazy" src={AR_Logo} width={"auto"} height={60} style={{justifySelf: "center", alignSelf: "center", display: "flex"}}/>
                        
                        
                    </Stack>
                </div>
            </Container>
        </div>
    );
}