import { CssBaseline, List, ListItem, Stack, Typography, useMediaQuery } from "@mui/material";
import startImage from "../assets/WEBP/faq_image5.webp";
import startImageHandy from "../assets/WEBP/faq_image5_handy.webp";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer";
import STT from "../components/Scroller/STT";
import ToRechner from "../components/RLE/RLE";
import NavbarMob from "../Mobile/components/Navbar/Navbar";
import ToRechnerMob from "../Mobile/components/RLE/RLE";
import FooterMob from "../Mobile/components/Footer/Footer";
import STTMob from "../Mobile/components/Scroller/STT";
import { Helmet } from 'react-helmet';


export default function  HSSite(){

    const isBig  = useMediaQuery('(min-width:600px)');
    
    if(isBig){

    return(
        <div id="header"> 
        <div style={{minHeight: "160vh",  backgroundColor: "#1a1b4b"}}>
            <Helmet>
                <title>Häufigste Schäden der Wohngebäudeversicherung</title>
                <meta name="description" content="Wasserschäden, Sturm/Hagel/Elementar, Brand/Feuer - wir bieten Ihnen den Schutz, den Sie benötigen."/>
                <meta name="keywords" content="Häufigste Schäden Wohngebäudeversicherung, Wohngebäudeversicherung Glas, Wohngebäudeversicherung Elementar, Wohngebäudeversicherung Schäden, Wohngebäudeversicherung Rückstau, Wohngebäudeversicherung Erfahrungen" />

            </Helmet>
            <CssBaseline/>
            <Navbar/>
            <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImage}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>

                <Typography variant="h1" sx={{justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Häufigste  Schäden</Typography>

            </div>
            <div style={{marginTop: 70,  marginBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                <div style={{width: "60vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                    <Stack direction="column" spacing={5}>
                    <Typography sx={{fontSize:  "1.2rem",}}>Die Wohngebäudeversicherung ist eine wichtige Absicherung für Hausbesitzer, da sie Schutz vor den finanziellen Folgen von Schäden am eigenen Haus bietet. Es gibt eine Vielzahl von möglichen Schäden, die durch eine Wohngebäudeversicherung übernommen werden können. Hier sind einige der häufigsten Schadensarten, die von einer Wohngebäudeversicherung abgedeckt werden:</Typography>

                    <List>
                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem", fontWeight:  "bold",  color: "#f7a400"}}>1.</span> <span style={{fontWeight: "bold"}}>Sturmschäden:</span> Stürme können erhebliche Schäden am Dach, an Fenstern, Türen oder Fassaden eines Hauses verursachen. Hierzu zählen auch Hagelschäden, die oft in Kombination mit Stürmen auftreten.</Typography>
                        </ListItem>

                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem", fontWeight:  "bold", color: "#f7a400"}}>2.</span> <span style={{fontWeight: "bold"}}>Leitungswasserschäden:</span> Leitungswasserschäden entstehen häufig durch Rohrbrüche oder undichte Stellen in Wasserleitungen. Sie können zu Feuchtigkeitsschäden an Wänden, Böden oder Decken führen, aber auch zur Beschädigung von Elektrogeräten.</Typography>
                        </ListItem>

                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem", fontWeight:  "bold", color: "#f7a400"}}>3.</span> <span style={{fontWeight: "bold"}}>Brand- und Blitzschäden:</span> Ein Brand kann das gesamte Haus zerstören oder schwere Schäden anrichten. Auch Blitzschläge können in Verbindung mit einem Brand zu erheblichen Schäden führen.</Typography>
                        </ListItem>

                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem", fontWeight:  "bold",  color: "#f7a400"}}>4.</span> <span style={{fontWeight: "bold"}}>Einbruchdiebstahl:</span> Einbrüche können zu Sachschäden am Haus oder Wohnungseinrichtungen führen. Eine Wohngebäudeversicherung kann hier den finanziellen Schaden mindern.</Typography>
                        </ListItem>

                        <ListItem>
                            <Typography sx={{fontSize:  "1.2rem",}}><span style={{fontSize: "3rem",fontWeight:  "bold", color: "#f7a400"}}>5.</span> <span style={{fontWeight: "bold"}}>Elementarschäden:</span> Hierunter fallen Schäden, die durch Naturereignisse wie Hochwasser, Erdbeben oder Schneedruck verursacht werden. Eine Wohngebäudeversicherung kann auch hier Schutz bieten.</Typography>
                        </ListItem>
                    </List>

                    <Typography sx={{fontSize:  "1.2rem",}}>Es ist wichtig zu beachten, dass nicht alle Wohngebäudeversicherungen alle oben genannten Schäden abdecken. Daher ist es ratsam, bei Vertragsabschluss darauf zu achten, welche Schäden im Versicherungsumfang enthalten sind. Auch kann es sein, dass einige Schäden nur gegen einen zusätzlichen Aufpreis versichert werden können. Es lohnt sich also, verschiedene Angebote von Versicherungsunternehmen zu vergleichen und sorgfältig abzuwägen, welche Versicherung am besten zu den individuellen Bedürfnissen passt.</Typography>
                    </Stack>
                
                </div>
            </div>

            <ToRechner/>

        </div>
        <Footer/>
        <STT/>
        </div>
    );

    }

    else if(!isBig){

        return(
            <div id="header"> 
            <div style={{minHeight: "160vh",  backgroundColor: "#1a1b4b"}}>
                <Helmet>
                    <title>Häufigste Schäden der Wohngebäudeversicherung</title>
                    <meta name="description" content="Wasserschäden, Sturm/Hagel/Elementar, Brand/Feuer - wir bieten Ihnen den Schutz, den Sie benötigen."/>
                    <meta name="keywords" content="Häufigste Schäden Wohngebäudeversicherung, Wohngebäudeversicherung Glas, Wohngebäudeversicherung Elementar, Wohngebäudeversicherung Schäden, Wohngebäudeversicherung Rückstau, Wohngebäudeversicherung Erfahrungen" />

                </Helmet>
                <CssBaseline/>
                <NavbarMob/>
                <div style={{height: "40vh", backgroundImage: `linear-gradient(to  bottom, rgba(0,0,0,0.4),rgba(0,0,0,0.4)),  url('${startImageHandy}')`, backgroundRepeat:  "no-repeat",  backgroundSize: "cover", justifyContent: "center",  alignContent: "center", display: "flex"}}>
    
                    <Typography sx={{fontSize: "15vw",justifySelf: "center",  alignSelf:  "center",  display: "flex", fontWeight:  "bold", color: "#ffffff"}}>Häufigste <br/> Schäden</Typography>
    
                </div>
                <div style={{marginTop: 70,  marginBottom: 70, justifyContent:  "center",  alignContent:  "center", display:  "flex"}}>
                    <div style={{width: "80vw",  justifyContent: "center", alignContent: "center",display: "flex",   color: "#ffffff"}}>
                        <Stack direction="column" spacing={5}>
                        <Typography sx={{fontSize:  "5vw",}}>Die Wohngebäudeversicherung ist eine wichtige Absicherung für Hausbesitzer, da sie Schutz vor den finanziellen Folgen von Schäden am eigenen Haus bietet. Es gibt eine Vielzahl von möglichen Schäden, die durch eine Wohngebäudeversicherung übernommen werden können. Hier sind einige der häufigsten Schadensarten, die von einer Wohngebäudeversicherung abgedeckt werden:</Typography>
    
                        <List>
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw", fontWeight:  "bold",  color: "#f7a400"}}>1.</span> <span style={{fontWeight: "bold"}}>Sturmschäden:</span> Stürme können erhebliche Schäden am Dach, an Fenstern, Türen oder Fassaden eines Hauses verursachen. Hierzu zählen auch Hagelschäden, die oft in Kombination mit Stürmen auftreten.</Typography>
                            </ListItem>
    
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw", fontWeight:  "bold", color: "#f7a400"}}>2.</span> <span style={{fontWeight: "bold"}}>Leitungswasserschäden:</span> Leitungswasserschäden entstehen häufig durch Rohrbrüche oder undichte Stellen in Wasserleitungen. Sie können zu Feuchtigkeitsschäden an Wänden, Böden oder Decken führen, aber auch zur Beschädigung von Elektrogeräten.</Typography>
                            </ListItem>
    
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw", fontWeight:  "bold", color: "#f7a400"}}>3.</span> <span style={{fontWeight: "bold"}}>Brand- und Blitzschäden:</span> Ein Brand kann das gesamte Haus zerstören oder schwere Schäden anrichten. Auch Blitzschläge können in Verbindung mit einem Brand zu erheblichen Schäden führen.</Typography>
                            </ListItem>
    
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw", fontWeight:  "bold",  color: "#f7a400"}}>4.</span> <span style={{fontWeight: "bold"}}>Einbruchdiebstahl:</span> Einbrüche können zu Sachschäden am Haus oder Wohnungseinrichtungen führen. Eine Wohngebäudeversicherung kann hier den finanziellen Schaden mindern.</Typography>
                            </ListItem>
    
                            <ListItem>
                                <Typography sx={{fontSize:  "5vw",}}><span style={{fontSize: "12vw",fontWeight:  "bold", color: "#f7a400"}}>5.</span> <span style={{fontWeight: "bold"}}>Elementarschäden:</span> Hierunter fallen Schäden, die durch Naturereignisse wie Hochwasser, Erdbeben oder Schneedruck verursacht werden. Eine Wohngebäudeversicherung kann auch hier Schutz bieten.</Typography>
                            </ListItem>
                        </List>
    
                        <Typography sx={{fontSize:  "5vw",}}>Es ist wichtig zu beachten, dass nicht alle Wohngebäudeversicherungen alle oben genannten Schäden abdecken. Daher ist es ratsam, bei Vertragsabschluss darauf zu achten, welche Schäden im Versicherungsumfang enthalten sind. Auch kann es sein, dass einige Schäden nur gegen einen zusätzlichen Aufpreis versichert werden können. Es lohnt sich also, verschiedene Angebote von Versicherungsunternehmen zu vergleichen und sorgfältig abzuwägen, welche Versicherung am besten zu den individuellen Bedürfnissen passt.</Typography>
                        </Stack>
                    
                    </div>
                </div>
    
                <ToRechnerMob/>
    
            </div>
            <FooterMob/>
            <STTMob/>
            </div>
        );


    }
}