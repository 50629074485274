import * as React from 'react';
import { Divider, Stack, Typography } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import logo  from  "../../assets/Versicherungen.png";



export default  function Footer(){


   
    
    return(
        <footer>
        <div style={{  minHeight:  "30vh",  backgroundColor: "#3e4491", justifySelf:  "center",  alignContent:  "center", color: "#ffffff"}}>
            
            <div style={{ display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', }}>
            <Stack sx={{display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', paddingTop: 15, marginBottom:  12,  width: "70vw"}} spacing={"auto"}  direction="row">
                <Stack spacing={2} direction="column">
                <Stack direction="row " sx={{justifyContent: "center",  alignContent: "center",display:  "flex"}}>
                    <EmailIcon  sx={{justifySelf: "center", alignSelf: "center", display: "flex", marginRight:  2,  fontSize: "3rem",  color: "#f7a400"}}/>
                    <Typography variant="h4"  sx={{alignSelf: "center",  fontWeight: "bold", justifySelf: "center", display: "flex"}}>E-Mail</Typography>
                    
                </Stack>
                    <div style={{justifyContent: "center", alignContent: "center", display: "flex"}}>
                        <Typography textAlign="center" sx={{fontSize:  "1.2rem", justifySelf: "center", alignSelf: "center", display: "flex"}}>versicherung@mr-money.de</Typography>
                    </div>
                </Stack>

                <Divider flexItem  orientation="vertical" sx={{background:  "#f7a400"}}/>

                <Stack spacing={2} direction="column">
                <Stack spacing={2} direction="row " sx={{justifyContent: "center",  alignContent: "center",display:  "flex"}}>
                    <CallIcon sx={{marginRight:  2,  fontSize: "3rem", color: "#f7a400", justifySelf: "center", alignSelf: "center", display: "flex"}}/>
                    <Typography variant="h4"  sx={{fontWeight: "bold", justifySelf: "center", alignSelf: "center", display: "flex"}}>Telefon</Typography>
                    
                </Stack>
                <div style={{justifyContent: "center", alignContent: "center", display: "flex"}}>
                    <Typography textAlign="center"  sx={{fontSize:  "1.2rem"}}>03 72 96 / 92 73 96</Typography>
                </div>
                </Stack>

                <Divider flexItem  orientation="vertical" sx={{background:  "#f7a400"}}/>
                
                <Stack spacing={2} direction="column">
                <Stack spacing={3} direction="row " sx={{justifyContent: "center",  alignContent: "center",display:  "flex"}}>
                    <SupportAgentIcon sx={{marginRight:  2,  fontSize: "3rem",  color: "#f7a400", justifySelf: "center", alignSelf: "center", display: "flex"}}/>
                    <Typography variant="h4"  sx={{fontWeight: "bold", justifySelf: "center", alignSelf: "center", display: "flex"}}>Öffnungszeiten</Typography>
                    
                </Stack>
                <div style={{justifyContent: "center", alignContent: "center", display: "flex"}}>
                    <Typography  textAlign="center" sx={{fontSize:  "1.2rem"}}>Montag - Freitag 08:30 - 17:30 Uhr</Typography>
                </div>
                </Stack>
            </Stack>
            </div>

            <div style={{ display: 'flex',
                alignItems: 'center',
                justifyContent: 'center', }}>
            <Stack direction="row" spacing={"auto"} sx={{ paddingBottom:  10, width: "70vw" }}>
                <Stack direction="row" >
                    <img alt="Wohngebäude Versicherungen" loading="lazy" src={logo} width={150} />
                    <Typography variant="h6" sx={{  alignSelf: "end",  paddingBottom: 1,  fontWeight:  "bold"}}>Wohngebäude<br/>Versicherungen</Typography>
                </Stack>

                <Stack direction="row" spacing={10} sx={{alignSelf:  "center"}} >
                    <Stack direction="column"  justifyItems={"center"} alignItems={"center"} display={"flex"}>
                        <Typography sx={{fontSize:  "1.4rem", fontWeight: "bold", color:  "#f7a400", textAlign:  "center"}}>Service</Typography>
                        <a href="/agb" title="Wohngebäudeversicherung:  Allgemeine Geschäftsbedingungen" style={{textDecoration: "none",  color: "#ffffff"}}><Typography sx={{textAlign: "center",  fontSize: "1.2rem", paddingTop: 2}}  >AGB</Typography></a>
                    </Stack>
                    <Stack direction="column" justifyItems={"center"} alignItems={"center"} display={"flex"}>
                        <Typography sx={{fontSize:  "1.4rem", fontWeight: "bold", color:  "#f7a400", textAlign:  "center"}}>Informationen</Typography>
                        <a href="/impressum" title="Wohngebäudeversicherung: Impressum" style={{textDecoration: "none",  color: "#ffffff"}}><Typography sx={{textAlign: "center",  fontSize: "1.2rem",  paddingTop: 2}}>Impressum</Typography></a>
                        <a href="/datenschutz" title="Wohngebäudeversicherung: Datenschutz" style={{textDecoration: "none",  color: "#ffffff"}}><Typography sx={{textAlign: "center",  fontSize: "1.2rem", paddingTop: 2}} >Datenschutz</Typography></a>
                    </Stack>
                    <Stack direction="column" justifyItems={"center"} alignItems={"center"} display={"flex"}>
                        <Typography sx={{fontSize:  "1.4rem", fontWeight: "bold", color:  "#f7a400", textAlign:  "center"}}>Kontakt</Typography>
                        <a href="/kontakt" title="Wohngebäudeversicherung: Kontaktformular" style={{textDecoration: "none",  color: "#ffffff"}}><Typography sx={{textAlign: "center",  fontSize: "1.2rem", paddingTop:  2}}>Kontakformular</Typography></a>
                    </Stack>
                </Stack>
                
            </Stack>
            </div>

            <div  style={{backgroundColor: "#292a73",color: "lightgray", paddingTop: 10, paddingBottom: 10}}>
                
                
                <Typography textAlign="center" fontWeight={"bold"}>Realisiert mit React</Typography>
            </div>

            
        </div>
        </footer>
    );
}

