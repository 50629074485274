import {useState}  from "react";

import { AppBar,  Divider, List, ListItem, MenuItem,  Stack,  } from "@mui/material";
import Drawer  from "@mui/material/Drawer";
import Slide from "@mui/material/Slide";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import logo from "../../assets/Versicherungen.png";

import  {Link as NormalLink} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';



export default function Navbar(){

    const trigger = useScrollTrigger();
    const  trigger1  = useScrollTrigger({disableHysteresis:  true});
    
    const [isOpen,  setIsOpen]  = useState(false);

    const toggleDrawer =  (open) => (event)  => {
        if(
            event.type === "keydown" && 
            ((event.key  === "Tab") || (event.key === "Shift")) 
        ){
            return;
        }

        setIsOpen(open);
    };


    const list = (anchor) => (
        <Box sx={{width: anchor === 'top' || anchor === 'bottom' ?  'auto' : 800  , padding: 5}}  role="presentation" >
            
            <IconButton component={Box} onClick={toggleDrawer(false)} sx={{borderRadius: "50%",  position: "absolute",  right:  20, top:  15, justifyItems: "center",  alignItems:  "center",  display: "flex",  fontSize: "5rem"}}>
                <CloseIcon comonent={IconButton}  sx={{fontSize:  "3rem", color: "#f7a400", }}/>
            </IconButton>
            
            <List sx={{color:  "#ffffff", }}>
                <ListItem sx={{marginTop: 5}}>
                    
                        <Typography textAlign="center" sx={{fontSize: "1.2rem", color: "#f7a400",  }}>Allgemein</Typography>
                    
                </ListItem>
                <ListItem>
                
                    <MenuItem onClick={toggleDrawer(false)}>
                        <a href="/" style={{textDecoration: "none",  color: "#ffffff"}} title="Wohngebäudeversicherung: Startseite" >
                            <Typography  textAlign="center" sx={{fontSize: "2.1rem"}}>Home</Typography>
                        </a>
                    </MenuItem>
                
                </ListItem>

                    

                <ListItem>
                    <MenuItem onClick={toggleDrawer(false)}>
                        <a href="/vergleichsrechner" style={{textDecoration: "none",  color: "#ffffff"}} title="Wohngebäudeversicherung: Vergleichsrechner" >
                            <Typography textAlign="center" sx={{fontSize: "2.1rem"}}>Vergleichsrechner</Typography>
                        </a>
                    </MenuItem>
                </ListItem>

                <ListItem>
                    <MenuItem onClick={toggleDrawer(false)}>
                        <a href="/kontakt" style={{textDecoration: "none",  color: "#ffffff"}} title="Wohngebäudeversicherung: Kontaktformular"  >
                            <Typography textAlign="center" sx={{fontSize: "2.1rem"}}>Kontakt</Typography>
                        </a>
                    </MenuItem>
                </ListItem>

                <Divider sx={{background:  "#f7a400", textAlign: "center",  marginTop: 5}} />

                <ListItem sx={{marginTop: 5}}>
                    
                        <Typography textAlign="center" sx={{fontSize: "1.2rem", color: "#f7a400"}}>Wissenswertes</Typography>
                    
                </ListItem>

                <Stack  direction="row" spacing={"auto"} sx={{marginRight: 5}}>
                <Stack  direction="column" >
                <ListItem >
                    <MenuItem>
                        <a href="/haeufigste_schaeden" style={{textDecoration: "none",  color: "#ffffff"}} title="Häufigste Schäden der Wohngebäudeversicherung" >
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Häufigste Schäden</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                <ListItem >
                    <MenuItem>
                        <a href="/glasbruch" style={{textDecoration: "none",  color: "#ffffff"}} title="Wohngebäudeversicherung: Glasbruch/Schäden" >
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Glasbruch</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                <ListItem >
                    <MenuItem>
                        <a href="/elementar" style={{textDecoration: "none",  color: "#ffffff"}} title="Wohngebäudeversicherung: Elementar" >
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Elementar</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                <ListItem >
                    <MenuItem>
                        <a href="/hochwasser" style={{textDecoration: "none",  color: "#ffffff"}} title="Wohngebäudeversicherung: Hochwasser" >
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Hochwasser</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                <ListItem >
                    <MenuItem>
                        <a href="/photovoltaik" style={{textDecoration: "none",  color: "#ffffff"}} title="Wohngebäudeversicherung: Photovoltaik">
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Photovoltaik</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                <ListItem >
                    <MenuItem>
                        <a href="/vergleiche_im_internet" title="Wohngebäudeversicherung:  Vergleiche" style={{textDecoration: "none",  color: "#ffffff"}}>
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Vergleiche im Internet</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                
                </Stack>
                <Stack direction="column">
                <ListItem >
                    <MenuItem>
                        <a href="/testsieger" title="Wohngebäudeversicherung: Testsieger" style={{textDecoration: "none",  color: "#ffffff"}}>
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Testsieger</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                <ListItem >
                    <MenuItem>
                        <a href="/erfahrungen_wg" title="Wohngebäudeversicherung: Erfahrungen" style={{textDecoration: "none",  color: "#ffffff"}}>
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Erfahrungen WG</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                <ListItem >
                    <MenuItem>
                        <a href="/neubau" style={{textDecoration: "none",  color: "#ffffff"}} title="Wohngebäudeversicherung: Neubau" >
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Neubau</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                <ListItem >
                    <MenuItem>
                        <a href="/wohnflaeche" style={{textDecoration: "none",  color: "#ffffff"}}  title="Wohngebäudeversicherung: Wohnfläche" >
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Wohnfläche</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                <ListItem >
                    <MenuItem>
                        <a href="/vermieter" style={{textDecoration: "none",  color: "#ffffff"}} title="Wohngebäudeversicherung: Vermieter" >
                            <Typography textAlign="center" sx={{fontSize: "1.5rem", }}>Vermieter</Typography>
                        </a>
                    </MenuItem>
                </ListItem>
                
                </Stack>
                </Stack>
            </List>
        </Box>
    );

    return  (
        <div>
            <Slide appear={false} direction="down" in={!trigger}>
                <AppBar sx={{backgroundColour: "transparent",  background: "transparent", paddingLeft:  "10%", paddingRight:  "10%", }}  elevation={0}>
                    <Toolbar sx={{color: "#ffffff",}}>
                    
                    
                        <Stack component={NormalLink} href="/" color={"#ffffff"} underline="none" direction="row" spacing={1.5} sx={{flexGrow: 1}}>
                        
                            <img loading="lazy" alt="Wohngebäude Versicherungen" src={logo} width={40}/>
                            {(trigger1 && window.scrollY) ?  <span></span>  : <Typography variant="h6" color="inherit" component="div" sx={{alignSelf:  "center",  fontWeight: "bold", fontFamily: 'Plus Jakarta Sans, sans-serif'}}>Wohngebäude Versicherungen</Typography>}
                            
                        </Stack>
                    
                        
                        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        
                    </Toolbar>
                </AppBar>
            </Slide>
            <div>
                <Drawer PaperProps={{sx: {backgroundColor: "rgba(26,27,75,  1)"}}}  variant="temporary"  anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
                    {list("right")}
                </Drawer>
            </div>
        </div>
    );
}